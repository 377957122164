import React from 'react';
import { motion } from 'framer-motion';
import { FileText, Download } from 'lucide-react';

export const Guides = () => {
  const guides = [
    {
      title: 'Getting Started with Sustainability',
      description: 'A comprehensive guide for businesses beginning their sustainability journey.',
      downloadLink: '#'
    },
    {
      title: 'ESG Reporting Best Practices',
      description: 'Learn how to create effective ESG reports that meet global standards.',
      downloadLink: '#'
    },
    {
      title: 'Carbon Footprint Reduction Strategies',
      description: 'Practical steps to reduce your organization\'s carbon footprint.',
      downloadLink: '#'
    }
  ];

  return (
    <div className="min-h-screen pt-32">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-6 font-poppins">
          Sustainability Guides
        </h1>
        <p className="text-xl text-slate-400 max-w-3xl mb-12">
          Access comprehensive guides and best practices for implementing sustainable business practices.
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {guides.map((guide, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-gray-900/50 backdrop-blur-lg rounded-xl p-6 border border-gray-800 hover:border-teal-500 transition-all"
            >
              <div className="w-12 h-12 bg-teal-500/10 rounded-lg flex items-center justify-center mb-4">
                <FileText className="w-6 h-6 text-teal-400" />
              </div>
              <h3 className="text-xl font-semibold text-white mb-2">{guide.title}</h3>
              <p className="text-slate-400 mb-4">{guide.description}</p>
              <a
                href={guide.downloadLink}
                className="inline-flex items-center text-teal-400 hover:text-teal-300 transition-colors"
              >
                <Download className="w-4 h-4 mr-2" />
                Download Guide
              </a>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};
