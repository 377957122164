import React from 'react';
import { motion } from 'framer-motion';
import { BookOpen, ArrowRight } from 'lucide-react';

export const CaseStudies = () => {
  const caseStudies = [
    {
      title: 'Tech Giant Achieves Carbon Neutrality',
      industry: 'Technology',
      description: 'How a leading tech company transformed their operations to achieve carbon neutrality.',
      link: '#'
    },
    {
      title: 'Manufacturing Plant Reduces Energy by 50%',
      industry: 'Manufacturing',
      description: 'A case study on implementing energy-efficient practices in manufacturing.',
      link: '#'
    },
    {
      title: 'Retail Chain\'s Sustainable Supply Chain',
      industry: 'Retail',
      description: 'Building a sustainable and efficient supply chain network.',
      link: '#'
    }
  ];

  return (
    <div className="min-h-screen pt-32">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-6 font-poppins">
          Case Studies
        </h1>
        <p className="text-xl text-slate-400 max-w-3xl mb-12">
          Learn from real-world examples of successful sustainability initiatives.
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {caseStudies.map((study, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-gray-900/50 backdrop-blur-lg rounded-xl p-6 border border-gray-800 hover:border-teal-500 transition-all"
            >
              <div className="w-12 h-12 bg-teal-500/10 rounded-lg flex items-center justify-center mb-4">
                <BookOpen className="w-6 h-6 text-teal-400" />
              </div>
              <div className="text-sm text-teal-400 mb-2">{study.industry}</div>
              <h3 className="text-xl font-semibold text-white mb-2">{study.title}</h3>
              <p className="text-slate-400 mb-4">{study.description}</p>
              <a
                href={study.link}
                className="inline-flex items-center text-teal-400 hover:text-teal-300 transition-colors"
              >
                Read Case Study
                <ArrowRight className="w-4 h-4 ml-2" />
              </a>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};
