import { motion } from 'framer-motion';
import { Check, Users, Database, Zap } from 'lucide-react';

export const Pricing = () => {
  const plans = [
    {
      name: "Free",
      subtitle: "For small businesses or startups",
      price: "₹0",
      period: "per month",
      features: [
        "Basic sustainability reports (3 projects)",
        "Pre-designed GRI compliance templates",
        "Basic carbon footprint calculator",
        "Dashboard with limited analytics (2 users)",
        "100 MB data storage",
        "Email support"
      ]
    },
    {
      name: "Professional",
      subtitle: "For mid-sized companies",
      price: "₹25,000",
      yearlyPrice: "₹2,50,000/year (save 20%)",
      period: "per month",
      features: [
        "Advanced reporting (Excel, PDF export)",
        "ERP/CRM system integrations",
        "Benchmarking and industry comparisons",
        "IoT-based real-time tracking",
        "AI-driven optimization suggestions",
        "Team collaboration (10 users)",
        "10 GB data storage",
        "Priority email and chat support"
      ],
      popular: true
    },
    {
      name: "Enterprise",
      subtitle: "For large corporations",
      price: "Custom",
      startingAt: "Starting at ₹10,00,000/year",
      period: "contact us",
      features: [
        "Fully customizable dashboards",
        "Unlimited projects and integrations",
        "Dedicated account manager",
        "AI-powered scenario analysis",
        "Global compliance monitoring",
        "Advanced employee gamification",
        "Unlimited users and storage",
        "24/7 dedicated technical support"
      ]
    }
  ];

  const addOns = [
    {
      icon: Users,
      name: "Premium Support",
      price: "₹10,000/month",
      description: "Get a dedicated support manager"
    },
    {
      icon: Database,
      name: "Advanced Analytics",
      price: "₹50,000/year",
      description: "Predictive analytics and custom KPIs"
    },
    {
      icon: Zap,
      name: "White-Label Solution",
      price: "₹5,00,000/year",
      description: "Custom branding and customization"
    }
  ];

  return (
    <div className="min-h-screen">
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
        className="pt-32 pb-20 px-4 sm:px-6 lg:px-8"
      >
        {/* Hero Section */}
        <div className="max-w-7xl mx-auto text-center">
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-6 font-poppins">
            Choose Your Plan
          </h1>
          <p className="text-xl text-slate-400 max-w-3xl mx-auto">
            Scale your sustainability initiatives with our flexible pricing options
          </p>
        </div>

        {/* Pricing Cards */}
        <div className="mt-16 max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {plans.map((plan, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 * index, duration: 0.6 }}
              className={`relative bg-gray-900/50 backdrop-blur-lg rounded-xl p-8 border ${
                plan.popular 
                  ? 'border-teal-500 shadow-lg shadow-teal-500/20 glow-effect-teal' 
                  : 'border-gray-800 glow-effect'
              }`}
            >
              {plan.popular && (
                <div className="absolute -top-4 left-1/2 -translate-x-1/2">
                  <span className="bg-teal-500 text-white px-3 py-1 rounded-full text-sm font-medium">
                    Most Popular
                  </span>
                </div>
              )}
              
              <div className="text-center mb-8">
                <h3 className="text-2xl font-bold text-white mb-2 font-poppins">{plan.name}</h3>
                <p className="text-slate-400 text-sm mb-4">{plan.subtitle}</p>
                <div className="mb-4">
                  <span className="text-4xl font-bold text-white">{plan.price}</span>
                  <span className="text-slate-400 ml-2">{plan.period}</span>
                  {plan.yearlyPrice && (
                    <p className="text-teal-400 text-sm mt-2">{plan.yearlyPrice}</p>
                  )}
                  {plan.startingAt && (
                    <p className="text-slate-400 text-sm mt-2">{plan.startingAt}</p>
                  )}
                </div>
              </div>

              <ul className="space-y-4 mb-8">
                {plan.features.map((feature, featureIndex) => (
                  <li key={featureIndex} className="flex items-start text-slate-300">
                    <Check className="w-5 h-5 text-teal-400 mr-3 flex-shrink-0 mt-1" />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>

              <button 
                className={`w-full py-3 px-6 rounded-lg font-medium transition-colors ${
                  plan.popular
                    ? 'bg-teal-500 text-white hover:bg-teal-600'
                    : 'bg-gray-800 text-white hover:bg-gray-700'
                }`}
              >
                {plan.name === "Enterprise" ? "Contact Sales" : "Get Started"}
              </button>
            </motion.div>
          ))}
        </div>

        {/* Add-ons Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8, duration: 0.6 }}
          className="mt-24 max-w-7xl mx-auto"
        >
          <h2 className="text-3xl font-bold text-white mb-12 font-poppins text-center">
            Enhance Your Experience
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {addOns.map((addon, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.02 }}
                className="bg-gray-900/30 backdrop-blur-lg rounded-xl p-6 border border-gray-800 hover:border-teal-500 transition-colors glow-effect-addon"
              >
                <div className="flex items-start">
                  <div className="w-12 h-12 bg-teal-500/10 rounded-lg flex items-center justify-center mr-4">
                    <addon.icon className="w-6 h-6 text-teal-400" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-white mb-2">{addon.name}</h3>
                    <p className="text-slate-400 text-sm mb-3">{addon.description}</p>
                    <p className="text-teal-400 font-medium">{addon.price}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* CTA Section */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1.4, duration: 0.6 }}
          className="mt-24 text-center"
        >
          <p className="text-slate-400 mb-4">
            Need help choosing the right plan?
          </p>
          <button className="inline-flex items-center px-6 py-3 mb-12 border border-teal-500 text-teal-400 rounded-lg hover:bg-teal-500/10 transition-colors">
            Schedule a Consultation
          </button>

          {/* Why Choose Ecoficient */}
          <div className="mt-24 max-w-7xl mx-auto">
            <h2 className="text-3xl font-bold text-white mb-8 font-poppins text-center">Why Choose Ecoficient?</h2>
            <p className="text-slate-400 text-center mb-12 max-w-3xl mx-auto">
              Ecoficient goes beyond just sustainability reporting. With cutting-edge features, AI-driven insights, and a user-friendly interface, we empower businesses to:
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <motion.div 
                whileHover={{ scale: 1.02 }}
                className="p-6 bg-gray-900/30 rounded-xl border border-gray-800 glow-effect"
              >
                <h3 className="text-xl font-semibold text-white mb-4">Simplify Sustainability Compliance</h3>
                <p className="text-slate-400">Stay ahead of ever-evolving regulations with automated compliance tools.</p>
              </motion.div>
              <motion.div 
                whileHover={{ scale: 1.02 }}
                className="p-6 bg-gray-900/30 rounded-xl border border-gray-800 glow-effect"
              >
                <h3 className="text-xl font-semibold text-white mb-4">Optimize Environmental Performance</h3>
                <p className="text-slate-400">Identify inefficiencies and implement data-backed solutions to reduce your carbon footprint.</p>
              </motion.div>
              <motion.div 
                whileHover={{ scale: 1.02 }}
                className="p-6 bg-gray-900/30 rounded-xl border border-gray-800 glow-effect"
              >
                <h3 className="text-xl font-semibold text-white mb-4">Drive Long-Term Value</h3>
                <p className="text-slate-400">Enhance your brand's reputation, reduce operational costs, and attract eco-conscious stakeholders.</p>
              </motion.div>
            </div>
          </div>

          {/* What's Included */}
          <div className="mt-24 max-w-7xl mx-auto">
            <h2 className="text-3xl font-bold text-white mb-12 font-poppins text-center">What's Included in Every Plan?</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[
                {
                  title: "Secure Cloud Storage",
                  description: "Reliable and scalable storage for your sustainability data."
                },
                {
                  title: "Seamless Integrations",
                  description: "Effortlessly connect with your existing tools like SAP, Salesforce, and more."
                },
                {
                  title: "Transparent Reporting",
                  description: "Create easy-to-understand reports that stakeholders will appreciate."
                },
                {
                  title: "Knowledge Base Access",
                  description: "Comprehensive guides, tutorials, and resources to help you make the most of the platform."
                },
                {
                  title: "Dedicated Support",
                  description: "Expert assistance to help you at every step of your sustainability journey."
                }
              ].map((item, index) => (
                <motion.div
                  key={index}
                  whileHover={{ scale: 1.02 }}
                  className="p-6 bg-gray-900/30 rounded-xl border border-gray-800 glow-effect"
                >
                  <h3 className="text-xl font-semibold text-white mb-3">{item.title}</h3>
                  <p className="text-slate-400">{item.description}</p>
                </motion.div>
              ))}
            </div>
          </div>

          {/* Success Stories */}
          <div className="mt-24 max-w-7xl mx-auto">
            <h2 className="text-3xl font-bold text-white mb-12 font-poppins text-center">Success Stories</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <motion.div
                whileHover={{ scale: 1.02 }}
                className="p-8 bg-gradient-to-br from-teal-500/10 to-blue-500/10 rounded-xl border border-teal-500/20 glow-effect"
              >
                <p className="text-xl text-slate-300 mb-6 italic">
                  "Ecoficient helped us reduce our carbon emissions by 25% in just one year!"
                </p>
                <p className="text-white font-semibold">John Doe</p>
                <p className="text-slate-400">Sustainability Manager at GreenCorp</p>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.02 }}
                className="p-8 bg-gradient-to-br from-teal-500/10 to-blue-500/10 rounded-xl border border-teal-500/20 glow-effect"
              >
                <p className="text-xl text-slate-300 mb-6 italic">
                  "Thanks to Ecoficient's AI tools, we've streamlined our compliance reporting process, saving countless hours and resources."
                </p>
                <p className="text-white font-semibold">Jane Smith</p>
                <p className="text-slate-400">Operations Lead at EcoIndustries</p>
              </motion.div>
            </div>
          </div>

          {/* FAQs */}
          <div className="mt-24 max-w-7xl mx-auto">
            <h2 className="text-3xl font-bold text-white mb-4 font-poppins text-center">Still Have Questions?</h2>
            <h3 className="text-2xl text-white mb-12 font-poppins text-center">Frequently Asked Questions</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-5xl mx-auto">
              {[
                {
                  question: "Which plan is right for me?",
                  answer: "If you're a small or medium-sized business, the Professional Tier offers the perfect balance of features and affordability. For larger enterprises with complex needs, we recommend the Enterprise Tier for its customizability and premium tools."
                },
                {
                  question: "Can I switch plans later?",
                  answer: "Absolutely! As your business grows, you can upgrade or downgrade your plan to match your needs."
                },
                {
                  question: "Is my data secure?",
                  answer: "Yes, Ecoficient uses AES-256 encryption and adheres to global standards like GDPR to ensure your data remains safe and confidential."
                },
                {
                  question: "Do you offer a free trial?",
                  answer: "Yes, you can explore our features with a 30-day free trial—no credit card required!"
                }
              ].map((faq, index) => (
                <motion.div
                  key={index}
                  whileHover={{ scale: 1.02 }}
                  className="p-6 bg-gray-900/30 rounded-xl border border-gray-800 glow-effect"
                >
                  <h4 className="text-xl font-semibold text-white mb-4">{faq.question}</h4>
                  <p className="text-slate-400">{faq.answer}</p>
                </motion.div>
              ))}
            </div>
          </div>

          {/* Commitment Section */}
          <div className="mt-24 max-w-4xl mx-auto">
            <h2 className="text-3xl font-bold text-white mb-6 font-poppins">Our Commitment to Your Growth</h2>
            <p className="text-slate-400 mb-12">
              At Ecoficient, we're not just a platform; we're your partner in building a sustainable future. 
              From personalized onboarding to ongoing support, we're here to ensure your journey toward sustainability 
              is seamless, impactful, and cost-efficient.
            </p>
            <div className="bg-gradient-to-r from-teal-500/10 to-blue-500/10 rounded-2xl p-8 border border-teal-500/20">
              <h3 className="text-2xl font-bold text-white mb-6">Ready to Transform Your Sustainability Goals?</h3>
              <p className="text-slate-400 mb-8">
                Take the first step today. Choose a plan that fits your needs, or contact our team for a personalized consultation.
              </p>
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <button className="px-8 py-3 bg-teal-500 text-white rounded-lg hover:bg-teal-600 transition-colors">
                  Get Started Now
                </button>
                <button className="px-8 py-3 border border-teal-500 text-teal-400 rounded-lg hover:bg-teal-500/10 transition-colors">
                  Schedule a Demo
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};
