import { motion } from 'framer-motion';
import { Calculator, FileText, BarChart3, Download, ArrowRight, Lightbulb, Leaf, Database } from 'lucide-react';

export const Resources = () => {
  const tools = [
    {
      icon: Calculator,
      title: "Carbon Footprint Calculator",
      description: "Calculate your organization's carbon emissions with our advanced AI-powered tool.",
      link: "/calculators/carbon-footprint"
    },
    {
      icon: BarChart3,
      title: "Sustainability Metrics Dashboard",
      description: "Visualize and track your environmental impact with real-time analytics.",
      link: "/tools/metrics-dashboard"
    },
    {
      icon: Database,
      title: "ESG Data Analyzer",
      description: "Comprehensive analysis of Environmental, Social, and Governance metrics.",
      link: "/tools/esg-analyzer"
    },
    {
      icon: Lightbulb,
      title: "Green Innovation Toolkit",
      description: "Resources and guides for implementing sustainable business practices.",
      link: "/tools/innovation-toolkit"
    }
  ];

  const resources = [
    {
      icon: FileText,
      title: "Sustainability Whitepapers",
      description: "In-depth research and insights on environmental sustainability.",
      downloadLink: "/downloads/whitepapers"
    },
    {
      icon: Leaf,
      title: "Best Practices Guide",
      description: "Comprehensive guide to implementing eco-friendly business practices.",
      downloadLink: "/downloads/best-practices"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-black to-gray-900">
      {/* Hero Section */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
        className="pt-32 pb-20 px-4 sm:px-6 lg:px-8"
      >
        <div className="max-w-7xl mx-auto text-center">
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-6 font-poppins">
            Sustainability Resources
          </h1>
          <p className="text-xl text-slate-400 max-w-3xl mx-auto">
            Explore our comprehensive suite of tools and resources designed to help your organization achieve its sustainability goals.
          </p>
        </div>
      </motion.div>

      {/* Tools Grid */}
      <motion.section 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.6 }}
        className="py-16 px-4 sm:px-6 lg:px-8"
      >
        <div className="max-w-7xl mx-auto">
          <h2 className="text-3xl font-bold text-white mb-12 font-poppins">Interactive Tools</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {tools.map((tool, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.02, translateY: -5 }}
                className="bg-gray-900/50 backdrop-blur-lg rounded-xl p-6 border border-gray-800 hover:border-teal-500 transition-colors"
              >
                <div className="w-12 h-12 bg-teal-500/10 rounded-lg flex items-center justify-center mb-4">
                  <tool.icon className="w-6 h-6 text-teal-400" />
                </div>
                <h3 className="text-xl font-semibold text-white mb-2 font-poppins">{tool.title}</h3>
                <p className="text-slate-400 mb-4">{tool.description}</p>
                <a 
                  href={tool.link}
                  className="inline-flex items-center text-teal-400 hover:text-teal-300 transition-colors"
                >
                  Try Now <ArrowRight className="w-4 h-4 ml-2" />
                </a>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section>

      {/* Downloads Section */}
      <motion.section 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4, duration: 0.6 }}
        className="py-16 px-4 sm:px-6 lg:px-8 bg-black/50"
      >
        <div className="max-w-7xl mx-auto">
          <h2 className="text-3xl font-bold text-white mb-12 font-poppins">Downloadable Resources</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {resources.map((resource, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.02 }}
                className="bg-gray-900/30 backdrop-blur-lg rounded-xl p-8 border border-gray-800 hover:border-teal-500 transition-colors"
              >
                <div className="flex items-start">
                  <div className="w-12 h-12 bg-teal-500/10 rounded-lg flex items-center justify-center mr-4">
                    <resource.icon className="w-6 h-6 text-teal-400" />
                  </div>
                  <div className="flex-1">
                    <h3 className="text-xl font-semibold text-white mb-2 font-poppins">{resource.title}</h3>
                    <p className="text-slate-400 mb-4">{resource.description}</p>
                    <a 
                      href={resource.downloadLink}
                      className="inline-flex items-center px-4 py-2 bg-teal-500/10 text-teal-400 rounded-lg hover:bg-teal-500/20 transition-colors"
                    >
                      <Download className="w-4 h-4 mr-2" /> Download
                    </a>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section>

      {/* CTA Section */}
      <motion.section 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.6, duration: 0.6 }}
        className="py-20 px-4 sm:px-6 lg:px-8"
      >
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-white mb-6 font-poppins">Ready to Start Your Sustainability Journey?</h2>
          <p className="text-slate-400 mb-8">
            Get started with our comprehensive suite of tools and resources designed to help your organization achieve its sustainability goals.
          </p>
          <button className="px-8 py-3 bg-teal-500 text-white rounded-lg hover:bg-teal-600 transition-colors font-medium">
            Get Started Now
          </button>
        </div>
      </motion.section>
    </div>
  );
};