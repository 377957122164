import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Calculator, Info, ArrowRight, RefreshCcw } from 'lucide-react';

interface EmissionFactors {
  electricity: number;
  naturalGas: number;
  fuel: number;
  waste: number;
}

const EMISSION_FACTORS: EmissionFactors = {
  electricity: 0.92, // kg CO2 per kWh
  naturalGas: 2.03, // kg CO2 per m3
  fuel: 2.31,  // kg CO2 per liter
  waste: 0.57  // kg CO2 per kg waste
};

export const CarbonCalculator = () => {
  const [formData, setFormData] = useState({
    electricity: '',
    naturalGas: '',
    fuel: '',
    waste: ''
  });

  const [results, setResults] = useState<{
    total: number;
    breakdown: {
      electricity: number;
      naturalGas: number;
      fuel: number;
      waste: number;
    } | null;
  }>({ total: 0, breakdown: null });

  const [showInfo, setShowInfo] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const calculateEmissions = (e: React.FormEvent) => {
    e.preventDefault();
    
    const breakdown = {
      electricity: Number(formData.electricity) * EMISSION_FACTORS.electricity,
      naturalGas: Number(formData.naturalGas) * EMISSION_FACTORS.naturalGas,
      fuel: Number(formData.fuel) * EMISSION_FACTORS.fuel,
      waste: Number(formData.waste) * EMISSION_FACTORS.waste
    };

    const total = Object.values(breakdown).reduce((acc, curr) => acc + curr, 0);

    setResults({
      total,
      breakdown
    });
  };

  const resetCalculator = () => {
    setFormData({
      electricity: '',
      naturalGas: '',
      fuel: '',
      waste: ''
    });
    setResults({ total: 0, breakdown: null });
  };

  return (
    <div className="flex-1 w-full">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        {/* Header */}
        <div className="text-center mb-12">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="flex items-center justify-center gap-3 mb-6"
          >
            <Calculator className="w-8 h-8 text-teal-400" />
            <h1 className="text-3xl font-bold text-white">Carbon Footprint Calculator</h1>
          </motion.div>
          <p className="text-gray-400 max-w-2xl mx-auto">
            Calculate your organization's carbon emissions based on energy consumption, transportation, and waste management.
          </p>
        </div>

        {/* Calculator Form */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.6 }}
          className="bg-gray-900/50 backdrop-blur-xl rounded-xl p-6 md:p-8 border border-white/10 mb-8 relative glow-effect"
        >
          <button
            onClick={() => setShowInfo(!showInfo)}
            className="absolute top-4 right-4 text-gray-400 hover:text-teal-400 transition-colors"
          >
            <Info className="w-5 h-5" />
          </button>

          {showInfo && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              className="absolute top-12 right-4 w-72 bg-gray-800 rounded-lg p-4 text-sm text-gray-300 shadow-xl border border-white/10"
            >
              <h4 className="font-semibold mb-2">Emission Factors Used:</h4>
              <ul className="space-y-1">
                <li>• Electricity: {EMISSION_FACTORS.electricity} kg CO2/kWh</li>
                <li>• Natural Gas: {EMISSION_FACTORS.naturalGas} kg CO2/m³</li>
                <li>• Fuel: {EMISSION_FACTORS.fuel} kg CO2/L</li>
                <li>• Waste: {EMISSION_FACTORS.waste} kg CO2/kg</li>
              </ul>
            </motion.div>
          )}

          <form onSubmit={calculateEmissions} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Monthly Electricity Usage (kWh)
                </label>
                <input
                  type="number"
                  name="electricity"
                  value={formData.electricity}
                  onChange={handleInputChange}
                  placeholder="Enter electricity usage"
                  className="w-full bg-gray-800/50 border border-gray-700 rounded-lg px-4 py-2.5 text-white placeholder-gray-500 focus:border-teal-500 focus:ring-1 focus:ring-teal-500 transition-colors"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Monthly Natural Gas Usage (m³)
                </label>
                <input
                  type="number"
                  name="naturalGas"
                  value={formData.naturalGas}
                  onChange={handleInputChange}
                  placeholder="Enter natural gas usage"
                  className="w-full bg-gray-800/50 border border-gray-700 rounded-lg px-4 py-2.5 text-white placeholder-gray-500 focus:border-teal-500 focus:ring-1 focus:ring-teal-500 transition-colors"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Monthly Fuel Consumption (L)
                </label>
                <input
                  type="number"
                  name="fuel"
                  value={formData.fuel}
                  onChange={handleInputChange}
                  placeholder="Enter fuel consumption"
                  className="w-full bg-gray-800/50 border border-gray-700 rounded-lg px-4 py-2.5 text-white placeholder-gray-500 focus:border-teal-500 focus:ring-1 focus:ring-teal-500 transition-colors"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Monthly Waste Generated (kg)
                </label>
                <input
                  type="number"
                  name="waste"
                  value={formData.waste}
                  onChange={handleInputChange}
                  placeholder="Enter waste generated"
                  className="w-full bg-gray-800/50 border border-gray-700 rounded-lg px-4 py-2.5 text-white placeholder-gray-500 focus:border-teal-500 focus:ring-1 focus:ring-teal-500 transition-colors"
                />
              </div>
            </div>

            <div className="flex items-center justify-end gap-4">
              <button
                type="button"
                onClick={resetCalculator}
                className="px-4 py-2 text-gray-400 hover:text-white transition-colors flex items-center gap-2"
              >
                <RefreshCcw className="w-4 h-4" />
                Reset
              </button>
              <button
                type="submit"
                className="bg-teal-500 hover:bg-teal-600 text-white px-6 py-2.5 rounded-lg font-medium transition-colors flex items-center gap-2 group glow-effect"
              >
                Calculate
                <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
              </button>
            </div>
          </form>
        </motion.div>

        {/* Results */}
        {results.breakdown && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-gray-900/50 backdrop-blur-xl rounded-xl p-6 md:p-8 border border-white/10 glow-effect"
          >
            <h2 className="text-2xl font-bold text-white mb-6">Carbon Footprint Results</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
              <div className="bg-gray-800/50 rounded-lg p-4 border border-gray-700">
                <div className="text-sm text-gray-400 mb-1">Electricity Emissions</div>
                <div className="text-2xl font-bold text-white">
                  {results.breakdown.electricity.toFixed(2)}
                  <span className="text-sm font-normal text-gray-400 ml-1">kg CO2</span>
                </div>
              </div>

              <div className="bg-gray-800/50 rounded-lg p-4 border border-gray-700">
                <div className="text-sm text-gray-400 mb-1">Natural Gas Emissions</div>
                <div className="text-2xl font-bold text-white">
                  {results.breakdown.naturalGas.toFixed(2)}
                  <span className="text-sm font-normal text-gray-400 ml-1">kg CO2</span>
                </div>
              </div>

              <div className="bg-gray-800/50 rounded-lg p-4 border border-gray-700">
                <div className="text-sm text-gray-400 mb-1">Fuel Emissions</div>
                <div className="text-2xl font-bold text-white">
                  {results.breakdown.fuel.toFixed(2)}
                  <span className="text-sm font-normal text-gray-400 ml-1">kg CO2</span>
                </div>
              </div>

              <div className="bg-gray-800/50 rounded-lg p-4 border border-gray-700">
                <div className="text-sm text-gray-400 mb-1">Waste Emissions</div>
                <div className="text-2xl font-bold text-white">
                  {results.breakdown.waste.toFixed(2)}
                  <span className="text-sm font-normal text-gray-400 ml-1">kg CO2</span>
                </div>
              </div>
            </div>

            <div className="bg-teal-500/10 rounded-lg p-6 border border-teal-500/20">
              <div className="text-lg text-gray-300 mb-2">Total Carbon Footprint</div>
              <div className="text-4xl font-bold text-white">
                {results.total.toFixed(2)}
                <span className="text-xl font-normal text-gray-400 ml-2">kg CO2</span>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
};
