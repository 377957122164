import { motion } from 'framer-motion';
import { Calendar, ArrowRight } from 'lucide-react';

export const News = () => {
  const articles = [
    {
      title: 'Global Sustainability Initiatives Making an Impact',
      date: 'June 1, 2023',
      category: 'Global News',
      description: 'An overview of successful sustainability initiatives from around the world.',
      readTime: '5 min read',
      link: '#'
    },
    {
      title: 'New Regulations Reshape Corporate Environmental Standards',
      date: 'May 28, 2023',
      category: 'Regulations',
      description: 'How recent policy changes are affecting corporate sustainability practices.',
      readTime: '8 min read',
      link: '#'
    },
    {
      title: 'Breakthrough in Renewable Energy Technology',
      date: 'May 25, 2023',
      category: 'Technology',
      description: 'Latest developments in renewable energy technology and their potential impact.',
      readTime: '6 min read',
      link: '#'
    }
  ];

  return (
    <div className="min-h-screen pt-32">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-6 font-poppins">
          Sustainability News
        </h1>
        <p className="text-xl text-slate-400 max-w-3xl mb-12">
          Stay updated with the latest news and developments in sustainability.
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {articles.map((article, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-gray-900/50 backdrop-blur-lg rounded-xl overflow-hidden border border-gray-800 hover:border-teal-500 transition-all"
            >
              <div className="aspect-video bg-gray-800 relative">
                <div className="absolute top-4 left-4">
                  <span className="bg-teal-500/20 text-teal-400 px-3 py-1 rounded-full text-sm">
                    {article.category}
                  </span>
                </div>
              </div>
              <div className="p-6">
                <div className="flex items-center text-slate-400 text-sm mb-4">
                  <Calendar className="w-4 h-4 mr-2" />
                  <span>{article.date}</span>
                  <span className="mx-2">•</span>
                  <span>{article.readTime}</span>
                </div>
                <h3 className="text-xl font-semibold text-white mb-2">{article.title}</h3>
                <p className="text-slate-400 mb-4">{article.description}</p>
                <a
                  href={article.link}
                  className="inline-flex items-center text-teal-400 hover:text-teal-300 transition-colors"
                >
                  Read Article
                  <ArrowRight className="w-4 h-4 ml-2" />
                </a>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};
