import React from 'react';
import { motion } from 'framer-motion';
import { Download, FileText, FileSpreadsheet, File } from 'lucide-react';

export const Downloads = () => {
  const resources = [
    {
      title: 'Sustainability Report Template',
      format: 'Excel',
      icon: FileSpreadsheet,
      description: 'A comprehensive template for creating sustainability reports.',
      downloadLink: '#'
    },
    {
      title: 'Carbon Calculation Spreadsheet',
      format: 'Excel',
      icon: FileSpreadsheet,
      description: 'Template for tracking and calculating carbon emissions.',
      downloadLink: '#'
    },
    {
      title: 'ESG Guidelines PDF',
      format: 'PDF',
      icon: FileText,
      description: 'Guidelines for implementing ESG practices.',
      downloadLink: '#'
    },
    {
      title: 'Renewable Energy Guide',
      format: 'PDF',
      icon: FileText,
      description: 'Guide to renewable energy implementation.',
      downloadLink: '#'
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-12"
        >
          <h1 className="text-4xl font-bold mb-4">Resource Downloads</h1>
          <p className="text-gray-400 max-w-2xl mx-auto">
            Access our collection of resources to help you implement sustainable practices
            and track your environmental impact.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {resources.map((resource, index) => (
            <motion.div
              key={resource.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-gray-800/50 rounded-lg p-6 hover:bg-gray-800/70 transition-all duration-300"
            >
              <div className="flex items-start space-x-4">
                <div className="p-3 bg-teal-500/10 rounded-lg">
                  <resource.icon className="w-6 h-6 text-teal-500" />
                </div>
                <div className="flex-1">
                  <h3 className="text-lg font-semibold mb-2">{resource.title}</h3>
                  <p className="text-gray-400 text-sm mb-4">{resource.description}</p>
                  <div className="flex items-center justify-between">
                    <span className="text-sm text-gray-500">{resource.format}</span>
                    <a
                      href={resource.downloadLink}
                      className="inline-flex items-center space-x-2 text-teal-500 hover:text-teal-400 transition-colors"
                    >
                      <Download className="w-4 h-4" />
                      <span>Download</span>
                    </a>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};
