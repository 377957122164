import React from 'react';
import { motion } from 'framer-motion';

export const AboutIllustration: React.FC = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 800 600"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.g
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        {/* Background Circle */}
        <circle cx="400" cy="300" r="250" fill="url(#gradient1)" fillOpacity="0.1" />
        
        {/* Decorative Elements */}
        <motion.path
          d="M300 200 Q400 100 500 200 T700 300"
          stroke="#22C55E"
          strokeWidth="2"
          strokeDasharray="10,10"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{ duration: 2, ease: "easeInOut" }}
        />
        
        {/* Sustainability Icons */}
        <motion.g
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.5, duration: 0.8 }}
        >
          {/* Solar Panel */}
          <rect x="300" y="250" width="80" height="100" rx="5" fill="#1F2937" />
          <rect x="310" y="260" width="20" height="20" fill="#22C55E" />
          <rect x="340" y="260" width="20" height="20" fill="#22C55E" />
          <rect x="310" y="290" width="20" height="20" fill="#22C55E" />
          <rect x="340" y="290" width="20" height="20" fill="#22C55E" />
        </motion.g>

        <motion.g
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.7, duration: 0.8 }}
        >
          {/* Wind Turbine */}
          <line x1="500" y1="250" x2="500" y2="350" stroke="#1F2937" strokeWidth="8" />
          <motion.g
            animate={{ rotate: 360 }}
            transition={{ duration: 8, repeat: Infinity, ease: "linear" }}
            style={{ transformOrigin: '500px 250px' }}
          >
            <path d="M500 250 L450 200 L500 220 Z" fill="#22C55E" />
            <path d="M500 250 L550 200 L500 220 Z" fill="#22C55E" />
            <path d="M500 250 L500 180 L480 220 Z" fill="#22C55E" />
          </motion.g>
        </motion.g>

        <motion.g
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.9, duration: 0.8 }}
        >
          {/* Graph */}
          <polyline
            points="200,400 300,350 400,380 500,320 600,300"
            stroke="#22C55E"
            strokeWidth="3"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle cx="600" cy="300" r="6" fill="#22C55E" />
        </motion.g>
      </motion.g>

      {/* Gradients */}
      <defs>
        <radialGradient id="gradient1" cx="50%" cy="50%" r="50%" gradientUnits="userSpaceOnUse">
          <stop offset="0%" stopColor="#22C55E" />
          <stop offset="100%" stopColor="#064E3B" />
        </radialGradient>
      </defs>
    </svg>
  );
};
