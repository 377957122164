import { motion } from 'framer-motion';
import { Calendar, User, Clock, ArrowRight } from 'lucide-react';

const BLOG_POSTS = [
  {
    title: 'The Future of Corporate Sustainability',
    excerpt: 'Exploring how AI and machine learning are revolutionizing sustainable business practices.',
    author: 'Sarah Chen',
    date: 'Nov 15, 2023',
    readTime: '5 min read',
    image: 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80&w=1000',
    category: 'Technology'
  },
  {
    title: 'Implementing Green Practices in Your Workplace',
    excerpt: 'A comprehensive guide to creating an eco-friendly office environment.',
    author: 'Michael Rodriguez',
    date: 'Nov 12, 2023',
    readTime: '4 min read',
    image: 'https://images.unsplash.com/photo-1518173946687-a4c8892bbd9f?auto=format&fit=crop&q=80&w=1000',
    category: 'Workplace'
  },
  {
    title: 'The Impact of Carbon Credits',
    excerpt: 'Understanding the role of carbon credits in corporate sustainability strategies.',
    author: 'Emma Thompson',
    date: 'Nov 10, 2023',
    readTime: '6 min read',
    image: 'https://images.unsplash.com/photo-1569163139599-0f4517e36f51?auto=format&fit=crop&q=80&w=1000',
    category: 'Environment'
  },
];

const FEATURED_POST = {
  title: 'Revolutionizing Sustainability with AI',
  excerpt: 'Discover how artificial intelligence is transforming the way companies approach environmental sustainability, from optimizing energy consumption to predicting environmental impacts.',
  author: 'Sarah Chen',
  date: 'Nov 20, 2023',
  readTime: '8 min read',
  image: 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80&w=2000',
  category: 'Featured'
};

export const Blog = () => {
  return (
    <div className="min-h-screen p-40">
      {/* Background Decorative Elements */}
      <div className="fixed inset-0 overflow-hidden pointer-events-none">
        <div className="absolute top-0 right-0 w-[1000px] h-[1000px] bg-gradient-to-br from-teal-500/10 to-transparent rounded-full blur-3xl"></div>
        <div className="absolute bottom-0 left-0 w-[800px] h-[800px] bg-gradient-to-tr from-teal-500/10 to-transparent rounded-full blur-3xl"></div>
        <div className="absolute inset-0 bg-[url('/grid-pattern.svg')] opacity-30"></div>
      </div>

      {/* Hero Section */}
      <section className="relative py-20 px-4 sm:px-6 lg:px-8">
        <div className="absolute inset-0 bg-black/40"></div>
        <div className="relative max-w-7xl mx-auto text-center">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Latest Insights & Updates
          </h1>
          <p className="text-xl text-gray-400 max-w-3xl mx-auto">
            Stay informed about the latest trends and innovations in corporate sustainability
          </p>
        </div>
      </section>

      {/* Featured Post */}
      <section className="relative py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-gray-900/50 backdrop-blur-xl rounded-xl overflow-hidden shadow-lg border border-white/10"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="h-full">
                <img
                  src={FEATURED_POST.image}
                  alt={FEATURED_POST.title}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="p-8 flex flex-col justify-center">
                <span className="text-teal-400 text-sm font-semibold mb-4">
                  {FEATURED_POST.category}
                </span>
                <h2 className="text-3xl font-bold text-white mb-4">
                  {FEATURED_POST.title}
                </h2>
                <p className="text-gray-400 mb-6">
                  {FEATURED_POST.excerpt}
                </p>
                <div className="flex items-center gap-6 text-sm text-gray-400">
                  <div className="flex items-center gap-2">
                    <User className="w-4 h-4" />
                    {FEATURED_POST.author}
                  </div>
                  <div className="flex items-center gap-2">
                    <Calendar className="w-4 h-4" />
                    {FEATURED_POST.date}
                  </div>
                  <div className="flex items-center gap-2">
                    <Clock className="w-4 h-4" />
                    {FEATURED_POST.readTime}
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Blog Posts Grid */}
      <section className="relative py-20 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-white mb-4">Latest Articles</h2>
            <p className="text-gray-400">Explore our recent insights and updates</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {BLOG_POSTS.map((post, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="bg-gray-900/50 backdrop-blur-xl rounded-xl overflow-hidden group cursor-pointer shadow-lg hover:shadow-xl transition-all duration-300 border border-white/10"
              >
                <div className="relative h-48">
                  <img
                    src={post.image}
                    alt={post.title}
                    className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                  />
                  <div className="absolute top-4 left-4">
                    <span className="bg-teal-500/90 backdrop-blur-sm text-white text-sm px-3 py-1 rounded-full">
                      {post.category}
                    </span>
                  </div>
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-semibold text-white mb-3 group-hover:text-teal-400 transition-colors">
                    {post.title}
                  </h3>
                  <p className="text-gray-400 mb-4">
                    {post.excerpt}
                  </p>
                  <div className="flex items-center justify-between text-sm text-gray-400">
                    <div className="flex items-center gap-4">
                      <div className="flex items-center gap-2">
                        <User className="w-4 h-4" />
                        {post.author}
                      </div>
                      <div className="flex items-center gap-2">
                        <Clock className="w-4 h-4" />
                        {post.readTime}
                      </div>
                    </div>
                    <ArrowRight className="w-5 h-5 text-teal-400 transform group-hover:translate-x-2 transition-transform" />
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};