import React from 'react';
import heroImage from '../../assets/hero.svg';

export const HeroSection: React.FC = () => {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="w-full max-w-[600px] mx-auto">
        <img 
          src={heroImage} 
          alt="Hero illustration" 
          className="w-full h-auto object-contain animate-float"
        />
      </div>
    </div>
  );
};
