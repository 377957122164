import { motion } from 'framer-motion';
import { Users, Target, Award } from 'lucide-react';

const MILESTONES = [
  {
    year: '2020',
    title: 'Foundation',
    description: 'Ecoficient was founded with a vision to revolutionize corporate sustainability.',
  },
  {
    year: '2021',
    title: 'Global Expansion',
    description: 'Expanded operations to serve clients across 20+ countries.',
  },
  {
    year: '2022',
    title: 'AI Integration',
    description: 'Launched AI-powered sustainability insights and recommendations.',
  },
  {
    year: '2023',
    title: 'Industry Recognition',
    description: 'Received multiple awards for innovation in sustainability tech.',
  },
];

const TEAM = [
  {
    name: 'Sarah Chen',
    role: 'CEO & Co-founder',
    image: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?auto=format&fit=crop&q=80&w=400',
  },
  {
    name: 'Michael Rodriguez',
    role: 'CTO',
    image: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?auto=format&fit=crop&q=80&w=400',
  },
  {
    name: 'Emma Thompson',
    role: 'Head of Sustainability',
    image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&q=80&w=400',
  },
];

export const About = () => {
  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <section className="pt-32 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto text-center">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Our Mission to Drive Sustainable Change
          </h1>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            We're building the future of corporate sustainability through innovative technology
            and data-driven solutions.
          </p>
        </div>
      </section>

      {/* Values Section */}
      <section className="py-20 px-4 sm:px-6 lg:px-8 bg-gray-900/50">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-white mb-4">Our Values</h2>
            <p className="text-gray-300">The principles that guide everything we do</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                icon: Target,
                title: 'Innovation',
                description: 'Pushing boundaries to create cutting-edge sustainability solutions',
              },
              {
                icon: Users,
                title: 'Collaboration',
                description: 'Working together to achieve greater environmental impact',
              },
              {
                icon: Award,
                title: 'Excellence',
                description: 'Maintaining the highest standards in everything we do',
              },
            ].map((value, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="bg-white/10 backdrop-blur-sm rounded-xl p-6 text-center"
              >
                <div className="w-12 h-12 bg-green-500/20 rounded-lg flex items-center justify-center mx-auto mb-4">
                  <value.icon className="w-6 h-6 text-green-400" />
                </div>
                <h3 className="text-xl font-semibold text-white mb-2">{value.title}</h3>
                <p className="text-gray-300">{value.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Timeline Section */}
      <section className="py-20 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-white mb-4">Our Journey</h2>
            <p className="text-gray-300">Milestones that shaped our growth</p>
          </div>
          <div className="space-y-8">
            {MILESTONES.map((milestone, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: index % 2 === 0 ? -20 : 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
                className="flex items-center gap-8"
              >
                <div className={`w-32 text-right ${index % 2 === 0 ? 'order-1' : 'order-1 md:order-2'}`}>
                  <span className="text-2xl font-bold text-green-400">{milestone.year}</span>
                </div>
                <div className="w-px h-24 bg-green-400/30 order-2"></div>
                <div className={`flex-1 bg-white/10 backdrop-blur-sm rounded-xl p-6 ${index % 2 === 0 ? 'order-3' : 'order-3 md:order-1'}`}>
                  <h3 className="text-xl font-semibold text-white mb-2">{milestone.title}</h3>
                  <p className="text-gray-300">{milestone.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Team Section */}
      <section className="py-20 px-4 sm:px-6 lg:px-8 bg-gray-900/50">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-white mb-4">Our Leadership Team</h2>
            <p className="text-gray-300">Meet the people driving our mission forward</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {TEAM.map((member, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="bg-white/10 backdrop-blur-sm rounded-xl overflow-hidden"
              >
                <img
                  src={member.image}
                  alt={member.name}
                  className="w-full h-64 object-cover"
                />
                <div className="p-6 text-center">
                  <h3 className="text-xl font-semibold text-white mb-1">{member.name}</h3>
                  <p className="text-green-400">{member.role}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;