import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Navbar } from './components/layout/Navbar';
import { Footer } from './components/layout/Footer';
import Home from './pages/Home';
import About from './pages/About';
import { Contact } from './pages/Contact';
import { Resources } from './pages/Resources';
import { Blog } from './pages/Blog';
import { Pricing } from './pages/Pricing';

// Calculator Routes
import CarbonFootprint from './pages/calculators/CarbonFootprint';
import EnergySavings from './pages/calculators/EnergySavings';
import BuildingEfficiency from './pages/calculators/BuildingEfficiency';
import RenewableROI from './pages/calculators/RenewableROI';

// Resource Routes
import { Guides } from './pages/resources/Guides';
import { CaseStudies } from './pages/resources/CaseStudies';
import { Tutorials } from './pages/resources/Tutorials';
import { Downloads } from './pages/resources/Downloads';

// Insight Routes
import { Industry, Experts, News, ESG } from './pages/insights';

// Dashboard and Tool Routes
import { MetricsDashboard } from './pages/dashboard/MetricsDashboard';
import { CustomerDashboard } from './components/dashboard/CustomerDashboard';
import { ESGAnalyzer } from './components/tools/ESGAnalyzer';
import { GreenInnovationToolkit } from './components/tools/GreenInnovationToolkit';
import Login from './components/auth/Login';
import Signup from './components/auth/Signup';

// Create router with future flags
const router = {
  future: {
    v7_startTransition: true,
    v7_relativeSplatPath: true,
  },
  basename: '/',
};

// Wrapper component to handle conditional rendering of Navbar and Footer
const AppContent = () => {
  const location = useLocation();
  const isDashboard = location.pathname === '/dashboard';
  const isAuthPage = ['/login', '/signup'].includes(location.pathname);

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-black to-gray-900 text-white">
      {!isDashboard && !isAuthPage && <Navbar />}
      <main className="flex-grow">
        <Routes>
          {/* Main Routes */}
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/resources" element={<Resources />} />

          {/* Calculator Routes */}
          <Route path="/calculators/carbon-footprint" element={<CarbonFootprint />} />
          <Route path="/calculators/energy-savings" element={<EnergySavings />} />
          <Route path="/calculators/building-efficiency" element={<BuildingEfficiency />} />
          <Route path="/calculators/renewable-roi" element={<RenewableROI />} />

          {/* Resource Routes */}
          <Route path="/resources/guides" element={<Guides />} />
          <Route path="/resources/case-studies" element={<CaseStudies />} />
          <Route path="/resources/tutorials" element={<Tutorials />} />
          <Route path="/resources/downloads" element={<Downloads />} />

          {/* Insight Routes */}
          <Route path="/insights/industry" element={<Industry />} />
          <Route path="/insights/experts" element={<Experts />} />
          <Route path="/insights/news" element={<News />} />
          <Route path="/insights/esg" element={<ESG />} />

          {/* Tool Routes */}
          <Route path="/tools/esg-analyzer" element={<ESGAnalyzer />} />
          <Route path="/tools/green-innovation" element={<GreenInnovationToolkit />} />
          <Route path="/tools/metrics-dashboard" element={<MetricsDashboard />} />
          <Route path="/dashboard" element={<CustomerDashboard />} />
        </Routes>
      </main>
      {!isDashboard && !isAuthPage && <Footer />}
    </div>
  );
};

const App = () => {
  return (
    <Router {...router}>
      <div className="app min-h-screen">
        <AppContent />
      </div>
    </Router>
  );
};

export default App;