import React from 'react';
import { motion } from 'framer-motion';
import { Mail, Phone, MapPin, MessageSquare } from 'lucide-react';

const CONTACT_INFO = [
  {
    icon: Phone,
    title: 'Phone',
    details: '+1 (555) 123-4567',
    description: 'Monday to Friday, 9am to 6pm PST'
  },
  {
    icon: Mail,
    title: 'Email',
    details: 'contact@ecoficient.com',
    description: "We'll respond within 24 hours"
  },
  {
    icon: MapPin,
    title: 'Office',
    details: 'San Francisco, CA',
    description: '123 Sustainability Street, 94105'
  }
];

export const Contact = () => {
  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
        className="pt-32 pb-20 px-4 sm:px-6 lg:px-8"
      >
        <div className="max-w-7xl mx-auto text-center">
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-6 font-poppins">
            Get in Touch
          </h1>
          <p className="text-xl text-slate-400 max-w-3xl mx-auto">
            Have questions about our solutions? We're here to help you create a more sustainable future.
          </p>
        </div>
      </motion.section>

      {/* Contact Information */}
      <motion.section
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.6 }}
        className="py-12 px-4 sm:px-6 lg:px-8"
      >
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {CONTACT_INFO.map((info, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.02 }}
                className="bg-gray-900/30 backdrop-blur-sm rounded-xl p-8 border border-gray-800 hover:border-teal-500 transition-all"
              >
                <div className="w-12 h-12 bg-teal-500/10 rounded-lg flex items-center justify-center mb-4">
                  <info.icon className="w-6 h-6 text-teal-400" />
                </div>
                <h3 className="text-xl font-semibold text-white mb-2 font-poppins">{info.title}</h3>
                <p className="text-teal-400 font-medium mb-1">{info.details}</p>
                <p className="text-slate-400">{info.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section>

      {/* Contact Form */}
      <motion.section
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4, duration: 0.6 }}
        className="py-20 px-4 sm:px-6 lg:px-8"
      >
        <div className="max-w-3xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-white mb-4 font-poppins">Send Us a Message</h2>
            <p className="text-slate-400">We'd love to hear from you</p>
          </div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-gray-900/30 backdrop-blur-sm rounded-xl p-8 border border-gray-800"
          >
            <form className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="firstName" className="block text-sm font-medium text-slate-400 mb-2">
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 text-white placeholder-gray-500 focus:ring-2 focus:ring-teal-500 focus:border-transparent transition-colors"
                    placeholder="John"
                  />
                </div>
                <div>
                  <label htmlFor="lastName" className="block text-sm font-medium text-slate-400 mb-2">
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 text-white placeholder-gray-500 focus:ring-2 focus:ring-teal-500 focus:border-transparent transition-colors"
                    placeholder="Doe"
                  />
                </div>
              </div>
              
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-slate-400 mb-2">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 text-white placeholder-gray-500 focus:ring-2 focus:ring-teal-500 focus:border-transparent transition-colors"
                  placeholder="john@example.com"
                />
              </div>

              <div>
                <label htmlFor="subject" className="block text-sm font-medium text-slate-400 mb-2">
                  Subject
                </label>
                <input
                  type="text"
                  id="subject"
                  className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 text-white placeholder-gray-500 focus:ring-2 focus:ring-teal-500 focus:border-transparent transition-colors"
                  placeholder="How can we help?"
                />
              </div>

              <div>
                <label htmlFor="message" className="block text-sm font-medium text-slate-400 mb-2">
                  Message
                </label>
                <textarea
                  id="message"
                  rows={6}
                  className="w-full bg-gray-900/50 border border-gray-800 rounded-lg px-4 py-2.5 text-white placeholder-gray-500 focus:ring-2 focus:ring-teal-500 focus:border-transparent transition-colors resize-none"
                  placeholder="Tell us about your project..."
                />
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full bg-teal-500 hover:bg-teal-600 text-white font-medium py-3 px-6 rounded-lg transition-colors flex items-center justify-center gap-2 group"
                >
                  Send Message
                  <MessageSquare className="w-5 h-5 group-hover:scale-110 transition-transform" />
                </button>
              </div>
            </form>
          </motion.div>
        </div>
      </motion.section>
    </div>
  );
};