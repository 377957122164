import { motion } from 'framer-motion';
import { Users, MessageCircle, Calendar, ArrowRight } from 'lucide-react';

export const Experts = () => {
  const experts = [
    {
      name: 'Dr. Sarah Chen',
      title: 'Sustainability Expert',
      expertise: 'Carbon Footprint Analysis',
      availability: 'Available for consultation',
      image: '/experts/sarah-chen.jpg',
      description: 'Expert in corporate sustainability and carbon footprint reduction strategies.',
      link: '#'
    },
    {
      name: 'Michael Rodriguez',
      title: 'Energy Efficiency Consultant',
      expertise: 'Renewable Energy',
      availability: 'Next available: June 15',
      image: '/experts/michael-rodriguez.jpg',
      description: 'Specializes in renewable energy implementation and optimization.',
      link: '#'
    },
    {
      name: 'Dr. Emma Thompson',
      title: 'ESG Strategy Advisor',
      expertise: 'ESG Reporting',
      availability: 'Available for consultation',
      image: '/experts/emma-thompson.jpg',
      description: 'Expert in ESG strategy development and reporting frameworks.',
      link: '#'
    }
  ];

  return (
    <div className="min-h-screen pt-32">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-6 font-poppins">
          Expert Network
        </h1>
        <p className="text-xl text-slate-400 max-w-3xl mb-12">
          Connect with leading sustainability experts for personalized guidance.
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {experts.map((expert, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-gray-900/50 backdrop-blur-lg rounded-xl overflow-hidden border border-gray-800 hover:border-teal-500 transition-all"
            >
              <div className="aspect-[4/3] bg-gray-800 relative">
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                <div className="absolute bottom-4 left-4">
                  <h3 className="text-xl font-semibold text-white">{expert.name}</h3>
                  <p className="text-teal-400">{expert.title}</p>
                </div>
              </div>
              <div className="p-6">
                <div className="flex items-center text-slate-400 mb-4">
                  <Users className="w-4 h-4 mr-2" />
                  <span>{expert.expertise}</span>
                </div>
                <div className="flex items-center text-slate-400 mb-4">
                  <Calendar className="w-4 h-4 mr-2" />
                  <span>{expert.availability}</span>
                </div>
                <p className="text-slate-400 mb-4">{expert.description}</p>
                <div className="flex justify-between items-center">
                  <a
                    href={expert.link}
                    className="inline-flex items-center text-teal-400 hover:text-teal-300 transition-colors"
                  >
                    View Profile
                    <ArrowRight className="w-4 h-4 ml-2" />
                  </a>
                  <button className="inline-flex items-center text-teal-400 hover:text-teal-300 transition-colors">
                    <MessageCircle className="w-4 h-4 mr-2" />
                    Contact
                  </button>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};
