import { motion } from 'framer-motion';

const BuildingEfficiency = () => {
  return (
    <div className="min-h-screen pt-32">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-6 font-poppins">
          Building Efficiency Calculator
        </h1>
        <p className="text-xl text-slate-400 max-w-3xl">
          Analyze your building's performance and identify areas for improvement.
        </p>
        
        {/* Calculator implementation will go here */}
        <div className="mt-12 bg-gray-900/50 backdrop-blur-lg rounded-xl p-8 border border-gray-800">
          <p className="text-slate-400">Calculator coming soon...</p>
        </div>
      </motion.div>
    </div>
  );
};

export default BuildingEfficiency;
