import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { HeroSection } from '../components/home/HeroSection';
import { FeatureCard } from '../components/home/FeatureCard';
import { AboutIllustration } from '../components/home/AboutIllustration';
import { 
  ClipboardCheck, 
  LineChart, 
  Brain, 
  ArrowUpRight,
  ArrowRight,
  Database,
  BarChart3,
  FileText,
  Calculator
} from 'lucide-react';

const Home = () => {
  return (
    <div className="relative min-h-screen bg-gradient-to-b from-gray-900 to-black">
      {/* Hero Section */}
      <div className="relative z-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="min-h-[80vh] flex flex-col lg:flex-row items-center justify-center gap-8 pt-40 pb-12">
            {/* Text Content */}
            <motion.div 
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
              className="flex-1 text-left lg:pr-8"
            >
              <motion.h1 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="text-4xl md:text-6xl lg:text-7xl font-bold tracking-tight text-white mb-6"
              >
                <span className="block">Revolutionize Your</span>
                <span className="block mt-2">
                  Energy <span className="text-green-400">Management</span>
                </span>
              </motion.h1>

              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
                className="max-w-xl text-base md:text-lg lg:text-xl text-gray-300 mb-10"
              >
                Transform your business's energy consumption with AI-powered insights and real-time monitoring. Save costs, reduce carbon footprint, and make data-driven decisions.
              </motion.p>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
                className="flex flex-col sm:flex-row gap-4 items-start"
              >
                <Link
                  to="/signup"
                  className="w-full sm:w-auto inline-flex justify-center items-center px-8 py-3 border border-transparent text-base font-medium rounded-lg text-white bg-green-600 hover:bg-green-700 transition-all duration-300 ease-in-out glow-effect"
                >
                  Start Free Trial
                </Link>
                <Link
                  to="/contact"
                  className="w-full sm:w-auto inline-flex justify-center items-center px-8 py-3 border border-gray-600 text-base font-medium rounded-lg text-white bg-transparent hover:bg-gray-800 transition-all duration-300 ease-in-out glow-effect-teal"
                >
                  Schedule Demo
                </Link>
              </motion.div>
            </motion.div>

            {/* Hero Image Container */}
            <motion.div 
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
              className="flex-1 relative w-full min-h-[500px] lg:min-h-[600px]"
            >
              <div className="absolute inset-0">
                <HeroSection />
              </div>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Key Features Section */}
      <section className="py-20 relative z-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
              Why Ecoficient? Transforming Sustainability Management
            </h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
              Our platform combines cutting-edge technology with sustainability expertise to help your business thrive in a green economy.
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <FeatureCard
              icon={ClipboardCheck}
              title="Automated Reporting"
              description="Generate audit-ready sustainability reports aligned with GRI, SASB, and TCFD standards."
              delay={0.1}
            />
            <FeatureCard
              icon={LineChart}
              title="Real-Time Insights"
              description="Monitor energy usage, carbon emissions, and more with live dashboards."
              delay={0.2}
            />
            <FeatureCard
              icon={Brain}
              title="AI-Driven Optimization"
              description="Receive actionable recommendations tailored to your business needs."
              delay={0.3}
            />
            <FeatureCard
              icon={ArrowUpRight}
              title="Scalable for Every Business"
              description="From small startups to large enterprises, Ecoficient grows with your needs."
              delay={0.4}
            />
          </div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            viewport={{ once: true }}
            className="text-center mt-12"
          >
            <Link
              to="/features"
              className="inline-flex items-center text-green-400 hover:text-green-300 transition-all duration-300 text-lg font-medium px-6 py-2 rounded-lg glow-effect"
            >
              Learn More About Features <ArrowRight className="ml-2 w-5 h-5" />
            </Link>
          </motion.div>
        </div>
      </section>

      {/* About Section */}
      <section className="py-20 bg-black/50 relative z-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col lg:flex-row items-center gap-12">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
              className="flex-1"
            >
              <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">
                A Platform Built for a Sustainable Future
              </h2>
              <p className="text-gray-400 mb-8 text-lg">
                At Ecoficient, we believe sustainability is more than just compliance—it's a business advantage. Our platform empowers organizations to reduce their environmental impact while optimizing costs and operations.
              </p>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
                <div className="text-center">
                  <h3 className="text-3xl font-bold text-green-400 mb-2">50+</h3>
                  <p className="text-gray-400">Enterprises Trust Us</p>
                </div>
                <div className="text-center">
                  <h3 className="text-3xl font-bold text-green-400 mb-2">25%</h3>
                  <p className="text-gray-400">Average Carbon Reduction</p>
                </div>
                <div className="text-center">
                  <h3 className="text-3xl font-bold text-green-400 mb-2">100%</h3>
                  <p className="text-gray-400">Regulatory Compliance</p>
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
              className="flex-1"
            >
              <div className="relative w-full aspect-square max-w-lg mx-auto">
                <AboutIllustration />
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="py-20 relative z-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
              Sustainability Made Simple in 3 Steps
            </h2>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <FeatureCard
              icon={Database}
              title="Connect Your Data"
              description="Seamless integration with your existing systems like SAP, Salesforce, and IoT devices."
              delay={0.1}
            />
            <FeatureCard
              icon={BarChart3}
              title="Analyze Performance"
              description="Use powerful dashboards to track metrics and benchmark against industry standards."
              delay={0.2}
            />
            <FeatureCard
              icon={FileText}
              title="Act & Report"
              description="Get AI-driven insights and generate compliance-ready reports effortlessly."
              delay={0.3}
            />
          </div>
        </div>
      </section>

      {/* Tools Section */}
      <section className="py-20 bg-black/50 relative z-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
              Start Your Sustainability Journey Today
            </h2>
            <p className="text-gray-400 max-w-2xl mx-auto mb-12">
              Try our free tools to get started on your sustainability journey
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
            <Link to="/tools/carbon-calculator">
              <motion.div
                whileHover={{ scale: 1.02 }}
                className="bg-gray-800/50 backdrop-blur-lg rounded-xl p-8 border border-gray-700 hover:border-green-500/50 transition-all duration-300 glow-effect"
              >
                <Calculator className="w-12 h-12 text-green-400 mb-4" />
                <h3 className="text-xl font-semibold text-white mb-2">Carbon Footprint Calculator</h3>
                <p className="text-gray-400 mb-4">Calculate your organization's carbon footprint and get personalized reduction strategies.</p>
                <span className="text-green-400 inline-flex items-center">
                  Try Calculator <ArrowRight className="ml-2 w-4 h-4" />
                </span>
              </motion.div>
            </Link>
            <Link to="/tools/savings-analyzer">
              <motion.div
                whileHover={{ scale: 1.02 }}
                className="bg-gray-800/50 backdrop-blur-lg rounded-xl p-8 border border-gray-700 hover:border-green-500/50 transition-all duration-300 glow-effect"
              >
                <LineChart className="w-12 h-12 text-green-400 mb-4" />
                <h3 className="text-xl font-semibold text-white mb-2">Energy Savings Analyzer</h3>
                <p className="text-gray-400 mb-4">Discover potential energy savings and get actionable recommendations.</p>
                <span className="text-green-400 inline-flex items-center">
                  Try Analyzer <ArrowRight className="ml-2 w-4 h-4" />
                </span>
              </motion.div>
            </Link>
          </div>
        </div>
      </section>

      {/* Final CTA Section */}
      <section className="py-20 relative z-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="text-center"
          >
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
              Take the First Step Toward a Sustainable Future
            </h2>
            <p className="text-gray-400 max-w-2xl mx-auto mb-8">
              Ecoficient is your partner in achieving sustainability goals while reducing costs and improving efficiency.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <Link
                to="/signup"
                className="inline-flex justify-center items-center px-8 py-3 border border-transparent text-base font-medium rounded-lg text-white bg-green-600 hover:bg-green-700 transition duration-300 ease-in-out transform hover:scale-105"
              >
                Start Free Trial
              </Link>
              <Link
                to="/contact"
                className="inline-flex justify-center items-center px-8 py-3 border border-gray-600 text-base font-medium rounded-lg text-white bg-transparent hover:bg-gray-800 transition duration-300 ease-in-out"
              >
                Talk to an Expert
              </Link>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default Home;