import React, { useState } from 'react';
import { motion } from 'framer-motion';
import {
  BarChart, Bar, LineChart, Line, PieChart, Pie,
  XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  ResponsiveContainer, Cell
} from 'recharts';
import {
  Upload, FileText, BarChart3, PieChart as PieChartIcon,
  Download, ArrowRight, AlertTriangle, CheckCircle,
  Info, RefreshCw, Filter, ChevronDown, Leaf, Users, Shield, Brain, Database, Target, Briefcase, TrendingUp, ActivitySquare, Zap
} from 'lucide-react';

// Sample ESG data
const sampleESGData = {
  environmental: {
    carbonEmissions: 120,
    energyUsage: 850,
    wasteManagement: 75,
    waterUsage: 320,
    renewableEnergy: 40
  },
  social: {
    employeeSatisfaction: 85,
    diversityScore: 78,
    communityEngagement: 92,
    healthAndSafety: 88,
    trainingHours: 45
  },
  governance: {
    boardDiversity: 70,
    ethicsCompliance: 95,
    riskManagement: 85,
    transparencyScore: 90,
    shareholderRights: 88
  }
};

const pieColors = ['#2dd4bf', '#0d9488', '#0f766e', '#134e4a'];

export const ESGAnalyzer: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'environmental' | 'social' | 'governance'>('environmental');
  const [timeRange, setTimeRange] = useState('1Y');
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [selectedMetric, setSelectedMetric] = useState<string | null>(null);

  // Convert ESG data for charts
  const getChartData = () => {
    const data = sampleESGData[activeTab];
    return Object.entries(data).map(([key, value]) => ({
      name: key.replace(/([A-Z])/g, ' $1').trim(),
      value: value,
      target: value * 1.2 // Sample target values
    }));
  };

  // Get overall ESG score
  const getOverallScore = () => {
    const scores = {
      environmental: 78,
      social: 85,
      governance: 88
    };
    return scores[activeTab];
  };

  // Analyze ESG data
  const analyzeData = async () => {
    setIsAnalyzing(true);
    // Simulate analysis delay
    await new Promise(resolve => setTimeout(resolve, 2000));
    setIsAnalyzing(false);
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-6 pt-40">
      {/* Header Section */}
      <div className="max-w-7xl mx-auto mb-8">
        <div className="flex justify-between items-center mb-6">
          <div>
            <h1 className="text-3xl font-bold mb-2">ESG Data Analyzer</h1>
            <p className="text-gray-400">Comprehensive analysis of your Environmental, Social, and Governance metrics</p>
          </div>
          <div className="flex items-center gap-4">
            <button
              onClick={() => setShowUploadModal(true)}
              className="flex items-center gap-2 px-4 py-2 bg-teal-500 text-white rounded-lg hover:bg-teal-600 transition-colors"
            >
              <Upload className="w-4 h-4" />
              Upload Data
            </button>
            <button className="p-2 rounded-lg bg-gray-800 text-gray-400 hover:bg-gray-700">
              <Download className="w-5 h-5" />
            </button>
          </div>
        </div>

        {/* Tab Navigation */}
        <div className="flex items-center gap-4 mb-6">
          {['environmental', 'social', 'governance'].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab as any)}
              className={`px-4 py-2 rounded-lg text-sm font-medium transition-all ${
                activeTab === tab
                  ? 'bg-teal-500 text-white'
                  : 'bg-gray-800 text-gray-400 hover:bg-gray-700'
              }`}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
          <div className="ml-auto flex items-center gap-4">
            <div className="flex items-center gap-2 bg-gray-800 rounded-lg p-2">
              <span className="text-sm text-gray-400">Time Range:</span>
              <select
                value={timeRange}
                onChange={(e) => setTimeRange(e.target.value)}
                className="bg-transparent text-white border-none focus:outline-none text-sm"
              >
                {['1M', '3M', '6M', '1Y', '2Y', '5Y'].map((range) => (
                  <option key={range} value={range}>{range}</option>
                ))}
              </select>
            </div>
            <button className="p-2 rounded-lg bg-gray-800 text-gray-400 hover:bg-gray-700">
              <Filter className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>

      {/* Main Content Grid */}
      <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Overview Card */}
        <motion.div
          className="bg-gray-800/50 backdrop-blur-xl p-6 rounded-xl border border-gray-700"
          whileHover={{ scale: 1.01 }}
        >
          <h3 className="text-lg font-semibold mb-4">Overall Score</h3>
          <div className="flex items-center justify-between mb-6">
            <div className="text-4xl font-bold text-white">{getOverallScore()}</div>
            <div className="text-sm text-green-400">+5% vs Last Year</div>
          </div>
          <div className="space-y-4">
            <div>
              <div className="flex justify-between text-sm mb-1">
                <span className="text-gray-400">Current Performance</span>
                <span className="text-white">78/100</span>
              </div>
              <div className="w-full bg-gray-700 rounded-full h-2">
                <div className="bg-teal-500 h-2 rounded-full" style={{ width: '78%' }} />
              </div>
            </div>
            <div>
              <div className="flex justify-between text-sm mb-1">
                <span className="text-gray-400">Industry Average</span>
                <span className="text-white">72/100</span>
              </div>
              <div className="w-full bg-gray-700 rounded-full h-2">
                <div className="bg-gray-500 h-2 rounded-full" style={{ width: '72%' }} />
              </div>
            </div>
          </div>
        </motion.div>

        {/* Main Chart */}
        <motion.div
          className="lg:col-span-2 bg-gray-800/50 backdrop-blur-xl p-6 rounded-xl border border-gray-700"
          whileHover={{ scale: 1.01 }}
        >
          <h3 className="text-lg font-semibold mb-4">Performance Metrics</h3>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={getChartData()}>
              <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
              <XAxis dataKey="name" stroke="#9ca3af" />
              <YAxis stroke="#9ca3af" />
              <Tooltip
                contentStyle={{ backgroundColor: '#1f2937', border: 'none' }}
                labelStyle={{ color: '#fff' }}
              />
              <Legend />
              <Bar dataKey="value" fill="#2dd4bf" name="Current" radius={[4, 4, 0, 0]} />
              <Bar dataKey="target" fill="#0d9488" name="Target" radius={[4, 4, 0, 0]} />
            </BarChart>
          </ResponsiveContainer>
        </motion.div>

        {/* Detailed Metrics */}
        <div className="lg:col-span-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {getChartData().map((metric, index) => (
            <motion.div
              key={index}
              className="bg-gray-800/50 backdrop-blur-xl p-6 rounded-xl border border-gray-700"
              whileHover={{ scale: 1.01 }}
            >
              <div className="flex justify-between items-start mb-4">
                <h4 className="text-lg font-semibold">{metric.name}</h4>
                <div className={`px-2 py-1 rounded text-sm ${
                  metric.value >= metric.target ? 'bg-green-500/20 text-green-400' : 'bg-yellow-500/20 text-yellow-400'
                }`}>
                  {metric.value >= metric.target ? 'On Track' : 'Needs Attention'}
                </div>
              </div>
              <div className="flex items-baseline gap-2 mb-4">
                <span className="text-2xl font-bold">{metric.value}</span>
                <span className="text-gray-400 text-sm">/ {metric.target} target</span>
              </div>
              <div className="w-full bg-gray-700 rounded-full h-2 mb-2">
                <div
                  className="bg-teal-500 h-2 rounded-full"
                  style={{ width: `${(metric.value / metric.target) * 100}%` }}
                />
              </div>
              <div className="flex justify-between text-sm text-gray-400">
                <span>Current: {metric.value}</span>
                <span>Target: {metric.target}</span>
              </div>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Upload Modal */}
      {showUploadModal && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            className="bg-gray-800 rounded-xl p-8 max-w-md w-full mx-4"
          >
            <div className="flex justify-between items-start mb-6">
              <h2 className="text-2xl font-semibold">Upload ESG Data</h2>
              <button
                onClick={() => setShowUploadModal(false)}
                className="p-2 hover:bg-gray-700 rounded-lg"
              >
                <RefreshCw className="w-5 h-5 text-gray-400" />
              </button>
            </div>
            <div className="space-y-4">
              <div
                className="border-2 border-dashed border-gray-700 rounded-xl p-8 text-center hover:border-teal-500 transition-colors cursor-pointer"
              >
                <Upload className="w-8 h-8 text-gray-400 mx-auto mb-4" />
                <p className="text-gray-400 mb-2">Drag and drop your ESG data files here</p>
                <p className="text-sm text-gray-500">or</p>
                <button className="mt-4 px-4 py-2 bg-teal-500 text-white rounded-lg hover:bg-teal-600 transition-colors">
                  Browse Files
                </button>
              </div>
              <div className="text-sm text-gray-400">
                <p className="flex items-center gap-2">
                  <Info className="w-4 h-4" />
                  Supported formats: CSV, XLSX, JSON
                </p>
              </div>
            </div>
          </motion.div>
        </div>
      )}

      {/* Why ESG Matters Section */}
      <motion.section
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className="max-w-7xl mx-auto mt-20 px-6"
      >
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-white mb-6">Why ESG Matters for Your Business</h2>
          <p className="text-lg text-gray-400 max-w-3xl mx-auto">
            As sustainability becomes a cornerstone of modern business strategy, leveraging ESG data is no longer optional. 
            With EcoFicient's ESG Data Analyzer, you can track, analyze, and optimize your company's ESG performance to meet 
            compliance standards, improve stakeholder trust, and create long-term value.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-20">
          {[
            {
              title: 'Environmental Metrics',
              description: 'Measure carbon emissions, water usage, and energy efficiency.',
              icon: Leaf
            },
            {
              title: 'Social Metrics',
              description: 'Track workplace diversity, employee wellbeing, and community impact.',
              icon: Users
            },
            {
              title: 'Governance Metrics',
              description: 'Evaluate compliance, board diversity, and leadership accountability.',
              icon: Shield
            }
          ].map((metric, index) => (
            <motion.div
              key={index}
              whileHover={{ scale: 1.02 }}
              className="bg-gray-800/50 backdrop-blur-xl p-8 rounded-xl border border-gray-700"
            >
              <metric.icon className="w-12 h-12 text-teal-400 mb-4" />
              <h3 className="text-xl font-semibold text-white mb-3">{metric.title}</h3>
              <p className="text-gray-400">{metric.description}</p>
            </motion.div>
          ))}
        </div>

        {/* Key Features Section */}
        <div className="mb-20">
          <h2 className="text-3xl font-bold text-white mb-12 text-center">Key Features of the ESG Data Analyzer</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                title: 'Real-Time ESG Dashboard',
                description: 'Visualize ESG metrics through intuitive graphs and charts.',
                icon: BarChart3
              },
              {
                title: 'Customizable Reports',
                description: 'Generate tailored ESG performance reports to meet regulatory and investor requirements.',
                icon: FileText
              },
              {
                title: 'AI-Driven Insights',
                description: 'Receive actionable suggestions to enhance your ESG strategy.',
                icon: Brain
              },
              {
                title: 'Data Integration',
                description: 'Seamlessly connect your existing data sources (e.g., Excel, cloud databases, APIs).',
                icon: Database
              },
              {
                title: 'Regulatory Compliance',
                description: 'Stay aligned with global ESG frameworks like GRI, SASB, and TCFD.',
                icon: Shield
              },
              {
                title: 'Performance Tracking',
                description: 'Monitor and benchmark your ESG performance against industry standards.',
                icon: Target
              }
            ].map((feature, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.02 }}
                className="bg-gray-800/50 backdrop-blur-xl p-6 rounded-xl border border-gray-700"
              >
                <feature.icon className="w-10 h-10 text-teal-400 mb-4" />
                <h3 className="text-xl font-semibold text-white mb-2">{feature.title}</h3>
                <p className="text-gray-400">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>

        {/* How It Works Section */}
        <div className="mb-20">
          <h2 className="text-3xl font-bold text-white mb-12 text-center">Analyze Your ESG Data in 3 Simple Steps</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                title: 'Upload Your Data',
                description: 'Import data from your preferred tools and platforms.',
                icon: Upload,
                step: '01'
              },
              {
                title: 'Analyze and Compare',
                description: 'Let our AI-powered system crunch the numbers to give you insights.',
                icon: BarChart3,
                step: '02'
              },
              {
                title: 'Take Action',
                description: 'Use detailed recommendations to make data-driven sustainability decisions.',
                icon: Zap,
                step: '03'
              }
            ].map((step, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.02 }}
                className="relative bg-gray-800/50 backdrop-blur-xl p-8 rounded-xl border border-gray-700"
              >
                <div className="absolute -top-4 -right-4 w-12 h-12 bg-teal-500 rounded-full flex items-center justify-center text-xl font-bold">
                  {step.step}
                </div>
                <step.icon className="w-12 h-12 text-teal-400 mb-4" />
                <h3 className="text-xl font-semibold text-white mb-3">{step.title}</h3>
                <p className="text-gray-400">{step.description}</p>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Use Cases Section */}
        <div className="mb-20">
          <h2 className="text-3xl font-bold text-white mb-12 text-center">Who Can Use the ESG Data Analyzer?</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {[
              {
                title: 'Sustainability Managers',
                description: 'Monitor your company\'s ESG metrics and identify improvement areas.',
                icon: Users
              },
              {
                title: 'Corporate Executives',
                description: 'Evaluate risks and opportunities in the context of ESG.',
                icon: Briefcase
              },
              {
                title: 'Investors',
                description: 'Assess portfolio companies\' ESG compliance and risk exposure.',
                icon: TrendingUp
              },
              {
                title: 'Consultants',
                description: 'Support clients with actionable ESG strategies backed by data.',
                icon: ActivitySquare
              }
            ].map((useCase, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.02 }}
                className="bg-gray-800/50 backdrop-blur-xl p-6 rounded-xl border border-gray-700"
              >
                <useCase.icon className="w-10 h-10 text-teal-400 mb-4" />
                <h3 className="text-lg font-semibold text-white mb-2">{useCase.title}</h3>
                <p className="text-gray-400 text-sm">{useCase.description}</p>
              </motion.div>
            ))}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="mb-20">
          <h2 className="text-3xl font-bold text-white mb-12 text-center">Frequently Asked Questions</h2>
          <div className="max-w-3xl mx-auto space-y-4">
            {[
              {
                question: 'What types of data can I upload into the ESG Data Analyzer?',
                answer: 'Our platform supports various data formats including CSV, Excel, JSON, and direct API connections. You can upload environmental metrics, social indicators, and governance data.'
              },
              {
                question: 'Is the tool compliant with global ESG reporting standards?',
                answer: 'Yes, our ESG Data Analyzer is aligned with major frameworks including GRI, SASB, and TCFD, ensuring your reporting meets global standards.'
              },
              {
                question: 'How secure is my company\'s ESG data?',
                answer: 'We implement bank-level security measures including encryption, secure data centers, and regular security audits to protect your sensitive information.'
              },
              {
                question: 'Can I integrate this tool with other software?',
                answer: 'Yes, we offer extensive API capabilities and pre-built integrations with popular business intelligence and sustainability management tools.'
              }
            ].map((faq, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.01 }}
                className="bg-gray-800/50 backdrop-blur-xl p-6 rounded-xl border border-gray-700"
              >
                <h3 className="text-lg font-semibold text-white mb-2">{faq.question}</h3>
                <p className="text-gray-400">{faq.answer}</p>
              </motion.div>
            ))}
          </div>
        </div>

        {/* CTA Section */}
        <motion.div
          whileHover={{ scale: 1.02 }}
          className="bg-gradient-to-r from-teal-500 to-teal-700 rounded-xl p-12 text-center mb-20"
        >
          <h2 className="text-3xl font-bold text-white mb-4">Ready to Transform Your ESG Strategy?</h2>
          <p className="text-lg text-white/90 mb-8">Leverage cutting-edge technology to turn data into impactful results.</p>
          <div className="flex flex-wrap justify-center gap-4">
            <button className="px-8 py-3 bg-white text-teal-700 rounded-lg hover:bg-gray-100 transition-colors font-medium">
              Start Free Trial
            </button>
            <button className="px-8 py-3 bg-transparent border-2 border-white text-white rounded-lg hover:bg-white/10 transition-colors font-medium">
              Schedule a Demo
            </button>
          </div>
        </motion.div>
      </motion.section>
    </div>
  );
};
