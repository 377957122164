import React from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Calculator,
  FileText,
  BookOpen,
  Video,
  BarChart2,
  Download,
  Lightbulb,
  Users,
  Building2,
  Wind,
  Leaf,
  TreePine,
  ArrowRight
} from 'lucide-react';

interface ResourcesMegaMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

const menuItems = {
  calculators: [
    { icon: Calculator, title: 'Carbon Footprint Calculator', description: 'Calculate your organization\'s emissions', link: '/calculators/carbon-footprint' },
    { icon: BarChart2, title: 'Energy Savings Analyzer', description: 'Discover potential energy savings', link: '/calculators/energy-savings' },
    { icon: Building2, title: 'Building Efficiency Tool', description: 'Analyze building performance', link: '/calculators/building-efficiency' },
    { icon: Wind, title: 'Renewable ROI Calculator', description: 'Calculate renewable energy returns', link: '/calculators/renewable-roi' },
  ],
  resources: [
    { icon: FileText, title: 'Sustainability Guides', description: 'Comprehensive guides and best practices', link: '/resources/guides' },
    { icon: BookOpen, title: 'Case Studies', description: 'Real-world success stories', link: '/resources/case-studies' },
    { icon: Video, title: 'Video Tutorials', description: 'Learn how to use our platform', link: '/resources/tutorials' },
    { icon: Download, title: 'Downloads', description: 'Templates and resources', link: '/resources/downloads' },
  ],
  insights: [
    { icon: Lightbulb, title: 'Industry Insights', description: 'Latest trends and analysis', link: '/insights/industry' },
    { icon: Users, title: 'Expert Network', description: 'Connect with sustainability experts', link: '/insights/experts' },
    { icon: Leaf, title: 'Sustainability News', description: 'Stay updated with latest news', link: '/insights/news' },
    { icon: TreePine, title: 'ESG Resources', description: 'ESG reporting guidelines', link: '/insights/esg' },
  ],
};

export const ResourcesMegaMenu: React.FC<ResourcesMegaMenuProps> = ({ isOpen, onClose }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <>
          {/* Desktop Backdrop - only shown on desktop */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed inset-0 bg-black/20 backdrop-blur-sm z-40 hidden md:block"
            onClick={onClose}
          />

          {/* Mega Menu - Different styling for mobile and desktop */}
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2 }}
            className="md:fixed relative w-full md:w-[90%] md:max-w-7xl bg-transparent md:bg-gray-900/95 md:backdrop-blur-lg md:border-t md:border-gray-800 md:shadow-2xl md:z-50 md:rounded-b-lg md:top-[64px] md:left-1/2 md:-translate-x-1/2 overflow-hidden"
          >
            <div className="w-full px-2 md:px-8 py-2 md:py-8 md:max-h-[calc(100vh-8rem)] md:overflow-y-auto">
              <div className="mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-12">
                  {/* Calculators Section */}
                  <div className="space-y-2 md:space-y-4">
                    <h3 className="text-base md:text-lg font-semibold text-white flex items-center pl-2 md:pl-0">
                      <Calculator className="w-4 h-4 md:w-5 md:h-5 mr-2 text-green-400" />
                      Calculators & Tools
                    </h3>
                    <div className="grid gap-1 md:gap-3">
                      {menuItems.calculators.map((item) => (
                        <Link
                          key={item.title}
                          to={item.link}
                          className="group p-2 md:p-3 rounded-lg hover:bg-gray-800/50 transition-all duration-200"
                          onClick={onClose}
                        >
                          <div className="flex items-start">
                            <item.icon className="w-4 h-4 md:w-5 md:h-5 text-green-400 mr-2 md:mr-3 mt-1 flex-shrink-0" />
                            <div className="flex-1 min-w-0">
                              <h4 className="text-sm md:text-base text-white group-hover:text-green-400 font-medium transition-colors truncate">
                                {item.title}
                              </h4>
                              <p className="text-xs md:text-sm text-gray-400 leading-relaxed line-clamp-2">
                                {item.description}
                              </p>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>

                  {/* Resources Section */}
                  <div className="space-y-2 md:space-y-4">
                    <h3 className="text-base md:text-lg font-semibold text-white flex items-center pl-2 md:pl-0">
                      <FileText className="w-4 h-4 md:w-5 md:h-5 mr-2 text-green-400" />
                      Resources
                    </h3>
                    <div className="grid gap-1 md:gap-3">
                      {menuItems.resources.map((item) => (
                        <Link
                          key={item.title}
                          to={item.link}
                          className="group p-2 md:p-3 rounded-lg hover:bg-gray-800/50 transition-all duration-200"
                          onClick={onClose}
                        >
                          <div className="flex items-start">
                            <item.icon className="w-4 h-4 md:w-5 md:h-5 text-green-400 mr-2 md:mr-3 mt-1 flex-shrink-0" />
                            <div className="flex-1 min-w-0">
                              <h4 className="text-sm md:text-base text-white group-hover:text-green-400 font-medium transition-colors truncate">
                                {item.title}
                              </h4>
                              <p className="text-xs md:text-sm text-gray-400 leading-relaxed line-clamp-2">
                                {item.description}
                              </p>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>

                  {/* Insights Section */}
                  <div className="space-y-2 md:space-y-4">
                    <h3 className="text-base md:text-lg font-semibold text-white flex items-center pl-2 md:pl-0">
                      <Lightbulb className="w-4 h-4 md:w-5 md:h-5 mr-2 text-green-400" />
                      Insights
                    </h3>
                    <div className="grid gap-1 md:gap-3">
                      {menuItems.insights.map((item) => (
                        <Link
                          key={item.title}
                          to={item.link}
                          className="group p-2 md:p-3 rounded-lg hover:bg-gray-800/50 transition-all duration-200"
                          onClick={onClose}
                        >
                          <div className="flex items-start">
                            <item.icon className="w-4 h-4 md:w-5 md:h-5 text-green-400 mr-2 md:mr-3 mt-1 flex-shrink-0" />
                            <div className="flex-1 min-w-0">
                              <h4 className="text-sm md:text-base text-white group-hover:text-green-400 font-medium transition-colors truncate">
                                {item.title}
                              </h4>
                              <p className="text-xs md:text-sm text-gray-400 leading-relaxed line-clamp-2">
                                {item.description}
                              </p>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};
