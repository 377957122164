import React from 'react';
import { motion } from 'framer-motion';
import { Video, Play } from 'lucide-react';

export const Tutorials = () => {
  const tutorials = [
    {
      title: 'Getting Started with Ecoficient',
      duration: '10:25',
      description: 'Learn the basics of using the Ecoficient platform.',
      videoLink: '#'
    },
    {
      title: 'Carbon Footprint Calculation Guide',
      duration: '15:30',
      description: 'A step-by-step guide to calculating your carbon footprint.',
      videoLink: '#'
    },
    {
      title: 'Sustainability Reporting Tutorial',
      duration: '20:15',
      description: 'Learn how to create comprehensive sustainability reports.',
      videoLink: '#'
    }
  ];

  return (
    <div className="min-h-screen pt-32">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-6 font-poppins">
          Video Tutorials
        </h1>
        <p className="text-xl text-slate-400 max-w-3xl mb-12">
          Learn how to use our platform effectively with our video tutorials.
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {tutorials.map((tutorial, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-gray-900/50 backdrop-blur-lg rounded-xl overflow-hidden border border-gray-800 hover:border-teal-500 transition-all"
            >
              <div className="relative aspect-video bg-gray-800 group">
                <Video className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-12 h-12 text-white opacity-50 group-hover:opacity-100 transition-opacity" />
                <div className="absolute bottom-2 right-2 bg-black/50 px-2 py-1 rounded text-sm text-white">
                  {tutorial.duration}
                </div>
              </div>
              <div className="p-6">
                <h3 className="text-xl font-semibold text-white mb-2">{tutorial.title}</h3>
                <p className="text-slate-400 mb-4">{tutorial.description}</p>
                <a
                  href={tutorial.videoLink}
                  className="inline-flex items-center text-teal-400 hover:text-teal-300 transition-colors"
                >
                  <Play className="w-4 h-4 mr-2" />
                  Watch Tutorial
                </a>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};
