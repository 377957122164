import React, { useState } from 'react';
import { motion } from 'framer-motion';
import {
  Lightbulb,
  Leaf,
  Recycle,
  Battery,
  Droplets,
  SunMedium,
  Factory,
  BarChart3,
  Download,
  CheckCircle,
  Settings2
} from 'lucide-react';

const GreenInnovationToolkit: React.FC = () => {
  const [activeTab, setActiveTab] = useState('assessment');
  const [] = useState(false);

  const innovationCategories = [
    {
      title: 'Renewable Energy',
      icon: SunMedium,
      description: 'Solar, wind, and alternative energy solutions',
      score: 85
    },
    {
      title: 'Waste Management',
      icon: Recycle,
      description: 'Circular economy and waste reduction strategies',
      score: 72
    },
    {
      title: 'Clean Technology',
      icon: Leaf,
      description: 'Eco-friendly technological innovations',
      score: 90
    },
    {
      title: 'Water Conservation',
      icon: Droplets,
      description: 'Water efficiency and treatment solutions',
      score: 68
    },
    {
      title: 'Energy Storage',
      icon: Battery,
      description: 'Battery and energy storage innovations',
      score: 78
    },
    {
      title: 'Industrial Efficiency',
      icon: Factory,
      description: 'Smart manufacturing and process optimization',
      score: 82
    }
  ];

  const tools = [
    {
      title: 'Innovation Assessment',
      icon: Lightbulb,
      description: 'Evaluate the sustainability impact of your innovations',
      tab: 'assessment'
    },
    {
      title: 'Green Tech Calculator',
      icon: Settings2,
      description: 'Calculate environmental benefits and ROI',
      tab: 'calculator'
    },
    {
      title: 'Performance Analytics',
      icon: BarChart3,
      description: 'Track and analyze your green innovation metrics',
      tab: 'analytics'
    }
  ];

  const renderScoreCard = (score: number) => {
    const getColor = (score: number) => {
      if (score >= 80) return 'text-green-400';
      if (score >= 60) return 'text-yellow-400';
      return 'text-red-400';
    };

    return (
      <div className={`text-2xl font-bold ${getColor(score)}`}>
        {score}%
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-40">
      {/* Header Section */}
      <div className="max-w-7xl mx-auto mb-12">
        <h1 className="text-4xl font-bold mb-4">Green Innovation Toolkit</h1>
        <p className="text-gray-400 text-lg">
          Transform your sustainability initiatives with our comprehensive suite of green innovation tools
        </p>
      </div>

      {/* Tools Navigation */}
      <div className="max-w-7xl mx-auto mb-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {tools.map((tool) => (
            <motion.button
              key={tool.tab}
              whileHover={{ scale: 1.02 }}
              onClick={() => setActiveTab(tool.tab)}
              className={`p-6 rounded-xl border ${
                activeTab === tool.tab
                  ? 'bg-teal-600 border-teal-500'
                  : 'bg-gray-800/50 border-gray-700 hover:bg-gray-800'
              } backdrop-blur-xl transition-all`}
            >
              <tool.icon className="w-8 h-8 mb-4 text-teal-400" />
              <h3 className="text-xl font-semibold mb-2">{tool.title}</h3>
              <p className="text-gray-400 text-sm">{tool.description}</p>
            </motion.button>
          ))}
        </div>
      </div>

      {/* Main Content Area */}
      <div className="max-w-7xl mx-auto">
        {activeTab === 'assessment' && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
          >
            {innovationCategories.map((category) => (
              <motion.div
                key={category.title}
                whileHover={{ scale: 1.02 }}
                className="bg-gray-800/50 backdrop-blur-xl p-6 rounded-xl border border-gray-700"
              >
                <div className="flex justify-between items-start mb-4">
                  <category.icon className="w-10 h-10 text-teal-400" />
                  {renderScoreCard(category.score)}
                </div>
                <h3 className="text-xl font-semibold mb-2">{category.title}</h3>
                <p className="text-gray-400">{category.description}</p>
              </motion.div>
            ))}
          </motion.div>
        )}

        {activeTab === 'calculator' && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-gray-800/50 backdrop-blur-xl p-8 rounded-xl border border-gray-700"
          >
            <h2 className="text-2xl font-bold mb-6">Green Technology ROI Calculator</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-2">
                    Implementation Cost ($)
                  </label>
                  <input
                    type="number"
                    className="w-full bg-gray-700 border border-gray-600 rounded-lg px-4 py-2 text-white"
                    placeholder="Enter cost"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-2">
                    Expected Annual Savings ($)
                  </label>
                  <input
                    type="number"
                    className="w-full bg-gray-700 border border-gray-600 rounded-lg px-4 py-2 text-white"
                    placeholder="Enter savings"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-2">
                    Carbon Reduction (tons/year)
                  </label>
                  <input
                    type="number"
                    className="w-full bg-gray-700 border border-gray-600 rounded-lg px-4 py-2 text-white"
                    placeholder="Enter reduction"
                  />
                </div>
                <button className="w-full bg-teal-600 hover:bg-teal-700 text-white font-medium py-2 px-4 rounded-lg transition-colors">
                  Calculate ROI
                </button>
              </div>
              <div className="bg-gray-700/50 rounded-lg p-6">
                <h3 className="text-xl font-semibold mb-4">Results</h3>
                <div className="space-y-4">
                  <div>
                    <p className="text-gray-400">Payback Period</p>
                    <p className="text-2xl font-bold text-teal-400">2.5 Years</p>
                  </div>
                  <div>
                    <p className="text-gray-400">ROI</p>
                    <p className="text-2xl font-bold text-teal-400">145%</p>
                  </div>
                  <div>
                    <p className="text-gray-400">Carbon Impact</p>
                    <p className="text-2xl font-bold text-teal-400">-250 tons CO2e</p>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}

        {activeTab === 'analytics' && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-gray-800/50 backdrop-blur-xl p-8 rounded-xl border border-gray-700"
          >
            <div className="flex justify-between items-center mb-8">
              <h2 className="text-2xl font-bold">Innovation Performance Analytics</h2>
              <button className="flex items-center gap-2 bg-teal-600 hover:bg-teal-700 text-white px-4 py-2 rounded-lg transition-colors">
                <Download className="w-4 h-4" />
                Export Report
              </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
              <div className="bg-gray-700/50 rounded-lg p-6">
                <h3 className="text-lg font-semibold mb-2">Innovation Score</h3>
                <p className="text-3xl font-bold text-teal-400">85/100</p>
                <p className="text-green-400 text-sm flex items-center gap-1 mt-2">
                  <CheckCircle className="w-4 h-4" /> Above Industry Average
                </p>
              </div>
              <div className="bg-gray-700/50 rounded-lg p-6">
                <h3 className="text-lg font-semibold mb-2">Projects</h3>
                <p className="text-3xl font-bold text-teal-400">12</p>
                <p className="text-gray-400 text-sm mt-2">Active Green Initiatives</p>
              </div>
              <div className="bg-gray-700/50 rounded-lg p-6">
                <h3 className="text-lg font-semibold mb-2">Carbon Impact</h3>
                <p className="text-3xl font-bold text-teal-400">-1,250</p>
                <p className="text-gray-400 text-sm mt-2">Tons CO2e Reduced</p>
              </div>
            </div>
            {/* Placeholder for charts */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="bg-gray-700/50 rounded-lg p-6 h-64 flex items-center justify-center">
                <p className="text-gray-400">Innovation Trends Chart</p>
              </div>
              <div className="bg-gray-700/50 rounded-lg p-6 h-64 flex items-center justify-center">
                <p className="text-gray-400">Impact Distribution Chart</p>
              </div>
            </div>
          </motion.div>
        )}
      </div>

      {/* Success Stories Section */}
      <div className="max-w-7xl mx-auto mt-20">
        <h2 className="text-2xl font-bold mb-8">Success Stories</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {[
            {
              title: 'Solar Implementation',
              company: 'Tech Corp',
              impact: '45% Energy Cost Reduction',
              icon: SunMedium
            },
            {
              title: 'Waste Reduction',
              company: 'Eco Manufacturing',
              impact: '70% Waste Eliminated',
              icon: Recycle
            },
            {
              title: 'Water Conservation',
              company: 'Green Foods',
              impact: '60% Water Saved',
              icon: Droplets
            }
          ].map((story, index) => (
            <motion.div
              key={index}
              whileHover={{ scale: 1.02 }}
              className="bg-gray-800/50 backdrop-blur-xl p-6 rounded-xl border border-gray-700"
            >
              <story.icon className="w-10 h-10 text-teal-400 mb-4" />
              <h3 className="text-xl font-semibold mb-2">{story.title}</h3>
              <p className="text-gray-400 mb-2">{story.company}</p>
              <p className="text-green-400 font-medium">{story.impact}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export { GreenInnovationToolkit };
