import { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Menu, X, ChevronDown } from 'lucide-react';
import { ResourcesMegaMenu } from './ResourcesMegaMenu';

// Import all logos
import lightLogo from '../../assets/logo/lightlogo.png';
import darkLogo from '../../assets/logo/darklogo.png';
import colorLogo from '../../assets/logo/colorlogo.png';

export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [isResourcesOpen, setIsResourcesOpen] = useState(false);
  const [logoError, setLogoError] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 20;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleLogoError = () => {
    setLogoError(true);
    console.error('Failed to load logo image');
  };

  return (
    <motion.nav
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={`fixed w-full z-50 transition-all duration-300 ${
        scrolled 
          ? 'bg-black/80 backdrop-blur-md border-b border-white/10' 
          : 'bg-transparent'
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="flex items-center justify-between h-20">
          {/* Logo */}
          <div className="flex-shrink-0 w-[180px]">
            <Link to="/" className="flex items-center space-x-2">
              {!logoError ? (
                <img 
                  src={lightLogo}
                  alt="Ecoficient Logo"
                  className="h-12 md:h-14 w-auto transition-all duration-200"
                  onError={handleLogoError}
                />
              ) : (
                <span className="text-xl font-bold text-white">Ecoficient</span>
              )}
            </Link>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex flex-1 items-center justify-center px-8">
            <div className="flex items-center justify-center space-x-8">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  `text-base md:text-lg font-medium transition-colors hover:text-teal-400 font-poppins ${
                    isActive ? 'text-teal-400' : 'text-slate-200'
                  }`
                }
              >
                Home
              </NavLink>
              <NavLink
                to="/about"
                className={({ isActive }) =>
                  `text-base md:text-lg font-medium transition-colors hover:text-teal-400 font-poppins ${
                    isActive ? 'text-teal-400' : 'text-slate-200'
                  }`
                }
              >
                About
              </NavLink>
              <div 
                className="relative"
                onMouseEnter={() => setIsResourcesOpen(true)}
                onMouseLeave={() => setIsResourcesOpen(false)}
              >
                <button 
                  className="text-base md:text-lg font-medium transition-colors hover:text-teal-400 font-poppins text-slate-200 flex items-center"
                >
                  Resources
                  <ChevronDown className="w-4 h-4 ml-1" />
                </button>
                <ResourcesMegaMenu 
                  isOpen={isResourcesOpen} 
                  onClose={() => setIsResourcesOpen(false)} 
                />
              </div>
              <NavLink
                to="/pricing"
                className={({ isActive }) =>
                  `text-base md:text-lg font-medium transition-colors hover:text-teal-400 font-poppins ${
                    isActive ? 'text-teal-400' : 'text-slate-200'
                  }`
                }
              >
                Pricing
              </NavLink>
              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  `text-base md:text-lg font-medium transition-colors hover:text-teal-400 font-poppins ${
                    isActive ? 'text-teal-400' : 'text-slate-200'
                  }`
                }
              >
                Contact
              </NavLink>
            </div>
          </div>

          {/* Auth Buttons */}
          <div className="hidden md:flex items-center justify-end space-x-4 min-w-[180px]">
            <Link
              to="/login"
              className="text-sm font-semibold text-white hover:text-teal-400 transition-colors whitespace-nowrap"
            >
              Login
            </Link>
            <Link
              to="/signup"
              className="text-sm font-semibold text-white bg-teal-600 hover:bg-teal-700 px-4 py-2 rounded-lg transition-all duration-300 inline-flex items-center whitespace-nowrap"
            >
              <span>Sign up</span>
              <span aria-hidden="true" className="ml-1 relative top-[1px]">→</span>
            </Link>
          </div>

          {/* Mobile menu button */}
          <div className="absolute right-4 md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-slate-200 hover:text-teal-400 transition-colors"
            >
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>

        {/* Mobile Navigation */}
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            className="md:hidden bg-black/95 backdrop-blur-lg absolute top-full left-0 right-0 border-t border-white/10 overflow-hidden max-h-[calc(100vh-4rem)]"
          >
            <div className="p-4 flex flex-col space-y-2 overflow-y-auto max-h-[calc(100vh-4rem)] scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-700">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  `text-base font-medium transition-colors hover:text-teal-400 font-poppins ${
                    isActive ? 'text-teal-400' : 'text-slate-200'
                  }`
                }
                onClick={() => setIsOpen(false)}
              >
                Home
              </NavLink>
              <NavLink
                to="/about"
                className={({ isActive }) =>
                  `text-base font-medium transition-colors hover:text-teal-400 font-poppins ${
                    isActive ? 'text-teal-400' : 'text-slate-200'
                  }`
                }
                onClick={() => setIsOpen(false)}
              >
                About
              </NavLink>
              
              {/* Resources Menu - Mobile */}
              <div className="relative">
                <button 
                  className="text-base font-medium transition-colors hover:text-teal-400 font-poppins text-slate-200 flex items-center"
                  onClick={() => setIsResourcesOpen(!isResourcesOpen)}
                >
                  Resources
                  <ChevronDown className={`w-4 h-4 ml-1 transition-transform duration-200 ${isResourcesOpen ? 'rotate-180' : ''}`} />
                </button>
                <ResourcesMegaMenu 
                  isOpen={isResourcesOpen} 
                  onClose={() => {
                    setIsResourcesOpen(false);
                    setIsOpen(false);
                  }} 
                />
              </div>

              <NavLink
                to="/pricing"
                className={({ isActive }) =>
                  `text-base font-medium transition-colors hover:text-teal-400 font-poppins ${
                    isActive ? 'text-teal-400' : 'text-slate-200'
                  }`
                }
                onClick={() => setIsOpen(false)}
              >
                Pricing
              </NavLink>
              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  `text-base font-medium transition-colors hover:text-teal-400 font-poppins ${
                    isActive ? 'text-teal-400' : 'text-slate-200'
                  }`
                }
                onClick={() => setIsOpen(false)}
              >
                Contact
              </NavLink>

              {/* Mobile Auth Buttons */}
              <div className="pt-4 flex flex-col space-y-2">
                <Link
                  to="/login"
                  className="w-full text-center text-sm font-semibold leading-6 text-white bg-transparent hover:bg-gray-700 px-4 py-2 rounded-lg transition duration-300"
                  onClick={() => setIsOpen(false)}
                >
                  Login
                </Link>
                <Link
                  to="/signup"
                  className="w-full text-center text-sm font-semibold leading-6 text-white bg-green-600 hover:bg-green-700 px-4 py-2 rounded-lg transition duration-300"
                  onClick={() => setIsOpen(false)}
                >
                  Sign up <span aria-hidden="true">&rarr;</span>
                </Link>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </motion.nav>
  );
};