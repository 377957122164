import { useState } from 'react';
import { motion } from 'framer-motion';
import {
  AreaChart, Area, BarChart, Bar,
  XAxis, YAxis, CartesianGrid, Tooltip,
  ResponsiveContainer
} from 'recharts';
import {
  Home, Target, AlertCircle, TreePine, Battery,
  Droplets, Recycle, BarChart3, FileText, BadgeCheck,
  GanttChart, Lightbulb, Factory, LineChart, PieChart,
  Globe, Settings2, Users, Webhook, ChevronLeft, ChevronRight,
  Search, Bell, Download, Calendar,
  Filter, Share2, X, InfoIcon, LogOut, TrendingUp} from 'lucide-react';
import logo from '../../assets/logo/jj.png';

// Sample data - To be replaced with real API data
const monthlyData = [
  { month: 'Jan', emissions: 120, energy: 150, water: 200, waste: 80 },
  { month: 'Feb', emissions: 110, energy: 140, water: 180, waste: 75 },
  { month: 'Mar', emissions: 130, energy: 160, water: 190, waste: 85 },
  { month: 'Apr', emissions: 100, energy: 130, water: 170, waste: 70 },
  { month: 'May', emissions: 90, energy: 120, water: 160, waste: 65 },
  { month: 'Jun', emissions: 95, energy: 125, water: 165, waste: 68 }
];

const timeRanges = ['Daily', 'Weekly', 'Monthly', 'Yearly'];

type InsightPriority = 'high' | 'medium' | 'low';

interface Insight {
  title: string;
  description: string;
  impact: string;
  priority: InsightPriority;
  category: string;
}

const insights: Insight[] = [
  {
    title: 'Energy Usage Spike',
    description: 'Unusual energy consumption detected in Building A',
    impact: 'High Impact',
    priority: 'high',
    category: 'Energy'
  },
  {
    title: 'Water Conservation',
    description: 'New water recycling system showing positive results',
    impact: 'Medium Impact',
    priority: 'medium',
    category: 'Water'
  },
  {
    title: 'Waste Reduction',
    description: 'Recycling program exceeding targets',
    impact: 'Positive Impact',
    priority: 'low',
    category: 'Waste'
  }
];

const activeProjects = [
  {
    name: 'Solar Panel Installation',
    status: 'on-track',
    progress: 75,
    deadline: '2024-03-15'
  },
  {
    name: 'Water Treatment Upgrade',
    status: 'delayed',
    progress: 45,
    deadline: '2024-02-28'
  },
  {
    name: 'Waste Management System',
    status: 'completed',
    progress: 100,
    deadline: '2024-01-31'
  }
];

const teamHighlights = [
  {
    name: 'Building A Team',
    achievement: 'Reduced energy consumption by 15%',
    impact: '+15% Efficiency',
    badge: 'Energy Champion'
  },
  {
    name: 'Facilities Team',
    achievement: 'Implemented new recycling program',
    impact: '30% Waste Reduction',
    badge: 'Innovation Leader'
  },
  {
    name: 'Operations Team',
    achievement: 'Optimized water usage patterns',
    impact: '20% Cost Savings',
    badge: 'Resource Optimizer'
  }
];

const industryBenchmarks = {
  carbonEmissions: {
    yourScore: 85,
    industry: 100,
    topPerformer: 70,
    unit: 'tons CO2e'
  },
  energyEfficiency: {
    yourScore: 82,
    industry: 100,
    topPerformer: 75,
    unit: 'kWh/sqft'
  },
  wasteManagement: {
    yourScore: 90,
    industry: 100,
    topPerformer: 85,
    unit: 'tons'
  }
};

const sustainabilityTips = [
  {
    title: 'Energy Saving',
    description: 'Switch to LED lighting to reduce energy consumption',
    impact: '30% Energy Reduction',
    category: 'energy'
  },
  {
    title: 'Water Conservation',
    description: 'Install smart irrigation systems',
    impact: '25% Water Savings',
    category: 'water'
  },
  {
    title: 'Waste Reduction',
    description: 'Implement paperless documentation',
    impact: '40% Paper Reduction',
    category: 'waste'
  }
];


export const CustomerDashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [selectedTimeRange, setSelectedTimeRange] = useState('Monthly');
  const [showInsightDetails, setShowInsightDetails] = useState(false);
  const [selectedInsight, setSelectedInsight] = useState<Insight | null>(null);
  const [showExportModal, setShowExportModal] = useState(false);


  const handleExport = (format: 'pdf' | 'csv' | 'excel') => {
    // In a real app, this would trigger an API call to generate and download the report
    console.log(`Exporting dashboard data in ${format} format`);
    setShowExportModal(false);
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Sidebar Toggle Button */}
      <button
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        className={`fixed top-4 left-4 z-50 p-2 rounded-lg bg-gray-800 hover:bg-gray-700 transition-all ${
          isSidebarOpen ? 'ml-[300px]' : 'ml-0'
        }`}
      >
        {isSidebarOpen ? <ChevronLeft className="w-5 h-5" /> : <ChevronRight className="w-5 h-5" />}
      </button>

      {/* Sidebar */}
      <motion.div
        initial={{ width: 320 }}
        animate={{ width: isSidebarOpen ? 320 : 0 }}
        transition={{ duration: 0.2 }}
        className="fixed top-0 left-0 h-screen flex-shrink-0 z-40"
      >
        <motion.div 
          initial={{ x: 0 }}
          animate={{ 
            x: isSidebarOpen ? 0 : -320
          }}
          transition={{ duration: 0.2 }}
          className="w-80 h-full bg-gray-800 border-r border-gray-700 flex flex-col"
        >
          {/* Fixed Header */}
          <div className="p-4 border-b border-gray-700">
            <div className="flex items-center gap-3">
              <img src={logo} alt="EcoMetrics Logo" className="w-8 h-8" />
              <h1 className="text-xl font-bold text-white">EcoMetrics</h1>
            </div>
          </div>

          {/* Scrollable Navigation */}
          <div className="flex-1 overflow-y-auto custom-scrollbar">
            <nav className="p-4 space-y-6">
              {/* Overview Section */}
              <div>
                <h2 className="px-4 text-xs font-semibold text-gray-400 uppercase tracking-wider">
                  Overview
                </h2>
                <div className="mt-2 space-y-1">
                  <button className="flex items-center gap-3 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700/50 w-full rounded-lg transition-colors whitespace-nowrap">
                    <Home className="w-5 h-5" />
                    Dashboard
                  </button>
                  <button className="flex items-center gap-3 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700/50 w-full rounded-lg transition-colors whitespace-nowrap">
                    <Target className="w-5 h-5" />
                    Goals & Targets
                  </button>
                  <button className="flex items-center gap-3 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700/50 w-full rounded-lg transition-colors whitespace-nowrap">
                    <AlertCircle className="w-5 h-5" />
                    Alerts & Notifications
                  </button>
                </div>
              </div>

              {/* Sustainability Metrics */}
              <div>
                <h2 className="px-4 text-xs font-semibold text-gray-400 uppercase tracking-wider">
                  Sustainability Metrics
                </h2>
                <div className="mt-2 space-y-1">
                  <button className="flex items-center gap-3 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700/50 w-full rounded-lg transition-colors whitespace-nowrap">
                    <TreePine className="w-5 h-5" />
                    Carbon Footprint
                  </button>
                  <button className="flex items-center gap-3 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700/50 w-full rounded-lg transition-colors whitespace-nowrap">
                    <Battery className="w-5 h-5" />
                    Energy Usage
                  </button>
                  <button className="flex items-center gap-3 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700/50 w-full rounded-lg transition-colors whitespace-nowrap">
                    <Droplets className="w-5 h-5" />
                    Water Management
                  </button>
                  <button className="flex items-center gap-3 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700/50 w-full rounded-lg transition-colors whitespace-nowrap">
                    <Recycle className="w-5 h-5" />
                    Waste & Recycling
                  </button>
                </div>
              </div>

              {/* ESG Management */}
              <div>
                <h2 className="px-4 text-xs font-semibold text-gray-400 uppercase tracking-wider">
                  ESG Management
                </h2>
                <div className="mt-2 space-y-1">
                  <button className="flex items-center gap-3 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700/50 w-full rounded-lg transition-colors whitespace-nowrap">
                    <BarChart3 className="w-5 h-5" />
                    ESG Analytics
                  </button>
                  <button className="flex items-center gap-3 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700/50 w-full rounded-lg transition-colors whitespace-nowrap">
                    <FileText className="w-5 h-5" />
                    Reports & Documents
                  </button>
                  <button className="flex items-center gap-3 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700/50 w-full rounded-lg transition-colors whitespace-nowrap">
                    <BadgeCheck className="w-5 h-5" />
                    Compliance
                  </button>
                </div>
              </div>

              {/* Projects & Innovation */}
              <div>
                <h2 className="px-4 text-xs font-semibold text-gray-400 uppercase tracking-wider">
                  Projects & Innovation
                </h2>
                <div className="mt-2 space-y-1">
                  <button className="flex items-center gap-3 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700/50 w-full rounded-lg transition-colors whitespace-nowrap">
                    <GanttChart className="w-5 h-5" />
                    Project Tracking
                  </button>
                  <button className="flex items-center gap-3 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700/50 w-full rounded-lg transition-colors whitespace-nowrap">
                    <Lightbulb className="w-5 h-5" />
                    Green Innovation
                  </button>
                  <button className="flex items-center gap-3 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700/50 w-full rounded-lg transition-colors whitespace-nowrap">
                    <Factory className="w-5 h-5" />
                    Supply Chain
                  </button>
                </div>
              </div>

              {/* Analytics & Reporting */}
              <div>
                <h2 className="px-4 text-xs font-semibold text-gray-400 uppercase tracking-wider">
                  Analytics & Reporting
                </h2>
                <div className="mt-2 space-y-1">
                  <button className="flex items-center gap-3 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700/50 w-full rounded-lg transition-colors whitespace-nowrap">
                    <LineChart className="w-5 h-5" />
                    Performance Trends
                  </button>
                  <button className="flex items-center gap-3 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700/50 w-full rounded-lg transition-colors whitespace-nowrap">
                    <PieChart className="w-5 h-5" />
                    Benchmarking
                  </button>
                  <button className="flex items-center gap-3 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700/50 w-full rounded-lg transition-colors whitespace-nowrap">
                    <Globe className="w-5 h-5" />
                    Global Impact
                  </button>
                </div>
              </div>

              {/* Settings & Support */}
              <div>
                <h2 className="px-4 text-xs font-semibold text-gray-400 uppercase tracking-wider">
                  Settings & Support
                </h2>
                <div className="mt-2 space-y-1">
                  <button className="flex items-center gap-3 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700/50 w-full rounded-lg transition-colors whitespace-nowrap">
                    <Settings2 className="w-5 h-5" />
                    Settings
                  </button>
                  <button className="flex items-center gap-3 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700/50 w-full rounded-lg transition-colors whitespace-nowrap">
                    <Users className="w-5 h-5" />
                    Team Management
                  </button>
                  <button className="flex items-center gap-3 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700/50 w-full rounded-lg transition-colors whitespace-nowrap">
                    <Webhook className="w-5 h-5" />
                    Integrations
                  </button>
                </div>
              </div>
            </nav>
          </div>

          {/* Fixed Footer */}
          <div className="p-4 border-t border-gray-700">
            <button className="flex items-center gap-3 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700/50 rounded-lg transition-colors w-full">
              <LogOut className="w-5 h-5" />
              Logout
            </button>
          </div>
        </motion.div>
      </motion.div>

      {/* Main content */}
      <div className={`transition-all duration-300 ${isSidebarOpen ? 'ml-[340px]' : 'ml-20'} pr-6`}>
        <div className="container mx-auto p-6">
          {/* Top Navigation Bar */}
          <div className="bg-gray-800/50 backdrop-blur-xl rounded-xl border border-gray-700 p-4 mb-6">
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-4">
                <div className="relative">
                  <Search className="w-4 h-4 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Search..."
                    className="pl-10 pr-4 py-2 bg-gray-700/50 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-teal-500"
                  />
                </div>
                <div className="flex items-center gap-2">
                  <button className="p-2 hover:bg-gray-700/50 rounded-lg">
                    <Bell className="w-5 h-5 text-gray-400" />
                  </button>
                  <button 
                    className="p-2 hover:bg-gray-700/50 rounded-lg"
                    onClick={() => setShowExportModal(true)}
                  >
                    <Download className="w-5 h-5 text-gray-400" />
                  </button>
                </div>
              </div>
              <div className="flex items-center gap-4">
                <span className="text-sm text-gray-400">Last updated: {new Date().toLocaleDateString()}</span>
                <button className="flex items-center gap-2 px-4 py-2 bg-teal-500 hover:bg-teal-600 rounded-lg text-white">
                  <Filter className="w-4 h-4" />
                  <span>Filters</span>
                </button>
              </div>
            </div>
          </div>

          {/* Welcome Section with Time Range Selector */}
          <div className="flex justify-between items-center mb-8">
            <div>
              <h1 className="text-3xl font-bold text-white mb-2">Welcome back, John!</h1>
              <p className="text-gray-400">Here's an overview of your organization's sustainability metrics.</p>
            </div>
            <div className="flex items-center gap-4">
              <div className="flex gap-2">
                {timeRanges.map((range) => (
                  <button
                    key={range}
                    onClick={() => setSelectedTimeRange(range)}
                    className={`px-4 py-2 rounded-lg text-sm font-medium transition-all ${
                      selectedTimeRange === range
                        ? 'bg-teal-500 text-white'
                        : 'bg-gray-800 text-gray-400 hover:bg-gray-700'
                    }`}
                  >
                    {range}
                  </button>
                ))}
              </div>
              <button className="p-2 rounded-lg bg-gray-800 text-gray-400 hover:bg-gray-700">
                <Filter className="w-5 h-5" />
              </button>
              <button className="p-2 rounded-lg bg-gray-800 text-gray-400 hover:bg-gray-700">
                <Share2 className="w-5 h-5" />
              </button>
              <button
                className="p-2 rounded-lg bg-gray-800 text-gray-400 hover:bg-gray-700"
              >
                <Download className="w-5 h-5" />
              </button>
            </div>
          </div>

          {/* Quick stats cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
            {[
              {
                title: 'Carbon Footprint',
                value: '12.5',
                unit: 'tons CO2e',
                trend: '-8.3%',
                positive: true,
                icon: Battery,
              },
              {
                title: 'Energy Usage',
                value: '1,420',
                unit: 'kWh',
                trend: '-5.2%',
                positive: true,
                icon: Battery,
              },
              {
                title: 'Sustainability Score',
                value: '85',
                unit: '/100',
                trend: '+2.1%',
                positive: true,
                icon: TrendingUp,
              },
              {
                title: 'Active Projects',
                value: '8',
                unit: 'initiatives',
                trend: '+1',
                positive: true,
                icon: BarChart3,
              },
            ].map((stat, index) => (
              <motion.div
                key={index}
                className="bg-gray-800/50 backdrop-blur-xl p-6 rounded-xl border border-gray-700 hover:border-teal-500/50 transition-all"
                whileHover={{ scale: 1.02 }}
              >
                <div className="flex items-center gap-4">
                  <div className="w-12 h-12 bg-teal-500/10 rounded-lg flex items-center justify-center">
                    <stat.icon className="w-6 h-6 text-teal-400" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-white">{stat.title}</h3>
                    <div className="flex items-baseline gap-2">
                      <span className="text-2xl font-bold text-white">{stat.value}</span>
                      <span className="text-sm text-gray-400">{stat.unit}</span>
                    </div>
                    <span className={`text-sm ${stat.positive ? 'text-green-400' : 'text-red-400'}`}>
                      {stat.trend}
                    </span>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>

          {/* Actionable Insights Section */}
          <motion.div
            className="bg-gray-800/50 backdrop-blur-xl p-6 rounded-xl border border-gray-700 mb-8"
            whileHover={{ scale: 1.01 }}
          >
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-lg font-semibold text-white">Actionable Insights</h3>
              <button className="p-2 hover:bg-gray-700/50 rounded-lg">
                <InfoIcon className="w-5 h-5 text-gray-400" />
              </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {[
                {
                  title: 'Energy Optimization',
                  description: 'Reduce lighting energy usage by implementing motion sensors',
                  impact: 'Potential 5% improvement in sustainability score',
                  deadline: '2 weeks',
                  priority: 'high'
                },
                {
                  title: 'Water Conservation',
                  description: 'Install water-efficient fixtures in Building A',
                  impact: 'Estimated savings of $2,000/month',
                  deadline: '1 month',
                  priority: 'medium'
                },
                {
                  title: 'Waste Management',
                  description: 'Implement new recycling program in cafeteria',
                  impact: 'Reduce waste by 30%',
                  deadline: '3 weeks',
                  priority: 'medium'
                }
              ].map((insight, index) => (
                <div key={index} className="bg-gray-700/30 rounded-lg p-4">
                  <div className="flex justify-between items-start mb-2">
                    <h4 className="font-medium text-white">{insight.title}</h4>
                    <span className={`text-sm px-2 py-1 rounded ${
                      insight.priority === 'high' ? 'bg-red-500/20 text-red-400' :
                      'bg-yellow-500/20 text-yellow-400'
                    }`}>
                      {insight.priority}
                    </span>
                  </div>
                  <p className="text-sm text-gray-400 mb-2">{insight.description}</p>
                  <div className="flex justify-between items-center text-sm">
                    <span className="text-teal-400">{insight.impact}</span>
                    <span className="text-gray-400">Due in {insight.deadline}</span>
                  </div>
                </div>
              ))}
            </div>
          </motion.div>

          {/* Main Charts Section */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
            {/* Emissions Chart */}
            <motion.div
              className="bg-gray-800/50 backdrop-blur-xl p-6 rounded-xl border border-gray-700"
              whileHover={{ scale: 1.01 }}
            >
              <h3 className="text-lg font-semibold text-white mb-4">Carbon Emissions Trend</h3>
              <ResponsiveContainer width="100%" height={300}>
                <AreaChart data={monthlyData}>
                  <defs>
                    <linearGradient id="colorEmissions" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#2dd4bf" stopOpacity={0.2}/>
                      <stop offset="95%" stopColor="#2dd4bf" stopOpacity={0}/>
                    </linearGradient>
                  </defs>
                  <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                  <XAxis dataKey="month" stroke="#9ca3af" />
                  <YAxis stroke="#9ca3af" />
                  <Tooltip
                    contentStyle={{ backgroundColor: '#1f2937', border: 'none' }}
                    labelStyle={{ color: '#fff' }}
                  />
                  <Area
                    type="monotone"
                    dataKey="emissions"
                    stroke="#2dd4bf"
                    fillOpacity={1}
                    fill="url(#colorEmissions)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </motion.div>

            {/* Energy Usage Chart */}
            <motion.div
              className="bg-gray-800/50 backdrop-blur-xl p-6 rounded-xl border border-gray-700"
              whileHover={{ scale: 1.01 }}
            >
              <h3 className="text-lg font-semibold text-white mb-4">Energy Consumption</h3>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={monthlyData}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                  <XAxis dataKey="month" stroke="#9ca3af" />
                  <YAxis stroke="#9ca3af" />
                  <Tooltip
                    contentStyle={{ backgroundColor: '#1f2937', border: 'none' }}
                    labelStyle={{ color: '#fff' }}
                  />
                  <Bar dataKey="energy" fill="#2dd4bf" radius={[4, 4, 0, 0]} />
                </BarChart>
              </ResponsiveContainer>
            </motion.div>
          </div>

          {/* Insights and Projects Section */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
            {/* Insights */}
            <motion.div
              className="bg-gray-800/50 backdrop-blur-xl p-6 rounded-xl border border-gray-700"
              whileHover={{ scale: 1.01 }}
            >
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-lg font-semibold text-white">Key Insights</h3>
                <InfoIcon className="w-5 h-5 text-gray-400" />
              </div>
              <div className="space-y-4">
                {insights.map((insight, index) => (
                  <div
                    key={index}
                    className="p-4 bg-gray-700/30 rounded-lg cursor-pointer hover:bg-gray-700/50 transition-all"
                    onClick={() => {
                      setSelectedInsight(insight);
                      setShowInsightDetails(true);
                    }}
                  >
                    <div className="flex justify-between items-start mb-2">
                      <h4 className="font-medium text-white">{insight.title}</h4>
                      <span className={`text-sm px-2 py-1 rounded ${
                        insight.priority === 'high' ? 'bg-red-500/20 text-red-400' :
                        insight.priority === 'medium' ? 'bg-yellow-500/20 text-yellow-400' :
                        'bg-green-500/20 text-green-400'
                      }`}>
                        {insight.impact}
                      </span>
                    </div>
                    <p className="text-sm text-gray-400">{insight.description}</p>
                  </div>
                ))}
              </div>
            </motion.div>

            {/* Active Projects */}
            <motion.div
              className="bg-gray-800/50 backdrop-blur-xl p-6 rounded-xl border border-gray-700"
              whileHover={{ scale: 1.01 }}
            >
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-lg font-semibold text-white">Active Projects</h3>
                <Calendar className="w-5 h-5 text-gray-400" />
              </div>
              <div className="space-y-4">
                {activeProjects.map((project, index) => (
                  <div key={index} className="p-4 bg-gray-700/30 rounded-lg">
                    <div className="flex justify-between items-start mb-2">
                      <h4 className="font-medium text-white">{project.name}</h4>
                      <span className={`text-sm px-2 py-1 rounded ${
                        project.status === 'on-track' ? 'bg-green-500/20 text-green-400' :
                        project.status === 'delayed' ? 'bg-red-500/20 text-red-400' :
                        'bg-blue-500/20 text-blue-400'
                      }`}>
                        {project.status}
                      </span>
                    </div>
                    <div className="w-full bg-gray-700 rounded-full h-2 mb-2">
                      <div
                        className="bg-teal-500 h-2 rounded-full"
                        style={{ width: `${project.progress}%` }}
                      />
                    </div>
                    <div className="flex justify-between items-center text-sm">
                      <span className="text-gray-400">Progress: {project.progress}%</span>
                      <span className="text-gray-400">Due: {project.deadline}</span>
                    </div>
                  </div>
                ))}
              </div>
            </motion.div>
          </div>

          {/* Team Highlights and Industry Benchmarks */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
            {/* Team Highlights */}
            <motion.div
              className="bg-gray-800/50 backdrop-blur-xl p-6 rounded-xl border border-gray-700"
              whileHover={{ scale: 1.01 }}
            >
              <h3 className="text-lg font-semibold text-white mb-6">Team Highlights</h3>
              <div className="space-y-4">
                {teamHighlights.map((highlight, index) => (
                  <div key={index} className="p-4 bg-gray-700/30 rounded-lg">
                    <div className="flex justify-between items-start mb-2">
                      <h4 className="font-medium text-white">{highlight.name}</h4>
                      <span className="text-sm px-2 py-1 rounded bg-teal-500/20 text-teal-400">
                        {highlight.badge}
                      </span>
                    </div>
                    <p className="text-sm text-gray-400 mb-2">{highlight.achievement}</p>
                    <span className="text-sm text-green-400">{highlight.impact}</span>
                  </div>
                ))}
              </div>
            </motion.div>

            {/* Industry Benchmarks */}
            <motion.div
              className="bg-gray-800/50 backdrop-blur-xl p-6 rounded-xl border border-gray-700"
              whileHover={{ scale: 1.01 }}
            >
              <h3 className="text-lg font-semibold text-white mb-6">Industry Benchmarks</h3>
              <div className="space-y-6">
                {Object.entries(industryBenchmarks).map(([key, data], index) => (
                  <div key={index} className="space-y-2">
                    <div className="flex justify-between text-sm text-gray-400">
                      <span className="capitalize">{key.replace(/([A-Z])/g, ' $1').trim()}</span>
                      <span>{data.unit}</span>
                    </div>
                    <div className="w-full bg-gray-700 rounded-full h-2">
                      <div
                        className="bg-teal-500 h-2 rounded-full"
                        style={{ width: `${(data.yourScore / data.industry) * 100}%` }}
                      />
                    </div>
                    <div className="flex justify-between text-sm">
                      <span className="text-teal-400">Your Score: {data.yourScore}</span>
                      <span className="text-gray-400">Industry Avg: {data.industry}</span>
                    </div>
                  </div>
                ))}
              </div>
            </motion.div>
          </div>

          {/* Sustainability Tips */}
          <motion.div
            className="bg-gray-800/50 backdrop-blur-xl p-6 rounded-xl border border-gray-700 mb-8"
            whileHover={{ scale: 1.01 }}
          >
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-lg font-semibold text-white">Sustainability Tips</h3>
              <Lightbulb className="w-5 h-5 text-gray-400" />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {sustainabilityTips.map((tip, index) => (
                <div key={index} className="p-4 bg-gray-700/30 rounded-lg">
                  <div className="flex justify-between items-start mb-2">
                    <h4 className="font-medium text-white">{tip.title}</h4>
                    <span className={`text-sm px-2 py-1 rounded ${
                      tip.category === 'energy' ? 'bg-yellow-500/20 text-yellow-400' :
                      tip.category === 'water' ? 'bg-blue-500/20 text-blue-400' :
                      'bg-green-500/20 text-green-400'
                    }`}>
                      {tip.impact}
                    </span>
                  </div>
                  <p className="text-sm text-gray-400">{tip.description}</p>
                </div>
              ))}
            </div>
          </motion.div>

          {/* Insight Details Modal */}
          {showInsightDetails && selectedInsight && (
            <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                className="bg-gray-800 rounded-xl p-8 max-w-2xl w-full mx-4"
              >
                <div className="flex justify-between items-start mb-6">
                  <h2 className="text-2xl font-semibold text-white">{selectedInsight.title}</h2>
                  <button
                    onClick={() => setShowInsightDetails(false)}
                    className="p-2 hover:bg-gray-700 rounded-lg"
                  >
                    <X className="w-5 h-5 text-gray-400" />
                  </button>
                </div>
                <p className="text-gray-400 mb-6">{selectedInsight.description}</p>
                <div className="grid grid-cols-2 gap-4 mb-6">
                  <div className="bg-gray-700/50 rounded-lg p-4">
                    <h3 className="text-sm font-medium text-gray-400 mb-2">Financial Impact</h3>
                    <p className="text-xl font-semibold text-white">{selectedInsight.impact}</p>
                  </div>
                  <div className="bg-gray-700/50 rounded-lg p-4">
                    <h3 className="text-sm font-medium text-gray-400 mb-2">Priority Level</h3>
                    <p className="text-xl font-semibold text-white capitalize">{selectedInsight.priority}</p>
                  </div>
                </div>
                <div className="flex justify-end gap-4">
                  <button
                    onClick={() => setShowInsightDetails(false)}
                    className="px-4 py-2 text-gray-400 hover:text-white"
                  >
                    Dismiss
                  </button>
                  <button className="px-4 py-2 bg-teal-500 text-white rounded-lg hover:bg-teal-600">
                    Take Action
                  </button>
                </div>
              </motion.div>
            </div>
          )}

          {/* Export Modal */}
          {showExportModal && (
            <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                className="bg-gray-800 rounded-xl p-8 max-w-md w-full mx-4"
              >
                <div className="flex justify-between items-start mb-6">
                  <h2 className="text-2xl font-semibold text-white">Export Dashboard</h2>
                  <button
                    onClick={() => setShowExportModal(false)}
                    className="p-2 hover:bg-gray-700 rounded-lg"
                  >
                    <X className="w-5 h-5 text-gray-400" />
                  </button>
                </div>
                <div className="space-y-4">
                  {['PDF Report', 'Excel Spreadsheet', 'CSV Data'].map((format) => (
                    <button
                      key={format}
                      onClick={() => handleExport(format.toLowerCase() as 'pdf' | 'csv' | 'excel')}
                      className="w-full p-4 bg-gray-700/30 rounded-lg text-left hover:bg-gray-700/50 transition-all"
                    >
                      <span className="text-white">{format}</span>
                    </button>
                  ))}
                </div>
              </motion.div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
