import { Link } from 'react-router-dom';
import { Facebook, Twitter, Instagram, Linkedin } from 'lucide-react';
import lightLogo from '../../assets/logo/lightlogo.png';

export const Footer = () => {
  return (
    <footer className="bg-black text-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Logo and Description */}
          <div className="col-span-1 md:col-span-1">
            <Link to="/" className="inline-block">
              <img 
                src={lightLogo} 
                alt="Ecoficient Logo" 
                className="h-12 w-auto mb-4"
              />
            </Link>
            <p className="text-slate-400 text-sm mt-4">
              Empowering businesses with sustainable solutions for a greener future.
            </p>
          </div>

          {/* Quick Links */}
          <div className="col-span-1">
            <h3 className="text-lg font-semibold mb-4 font-poppins">Quick Links</h3>
            <ul className="space-y-3">
              <li>
                <Link to="/about" className="text-slate-400 hover:text-teal-400 transition-colors">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/blog" className="text-slate-400 hover:text-teal-400 transition-colors">
                  Blog
                </Link>
              </li>
              <li>
                <Link to="/resources" className="text-slate-400 hover:text-teal-400 transition-colors">
                  Resources
                </Link>
              </li>
              <li>
                <Link to="/pricing" className="text-slate-400 hover:text-teal-400 transition-colors">
                  Pricing
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-slate-400 hover:text-teal-400 transition-colors">
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          {/* Resources */}
          <div className="col-span-1">
            <h3 className="text-lg font-semibold mb-4 font-poppins">Resources</h3>
            <ul className="space-y-3">
              <li>
                <Link to="/documentation" className="text-slate-400 hover:text-teal-400 transition-colors">
                  Documentation
                </Link>
              </li>
              <li>
                <Link to="/support" className="text-slate-400 hover:text-teal-400 transition-colors">
                  Support Center
                </Link>
              </li>
              <li>
                <Link to="/faq" className="text-slate-400 hover:text-teal-400 transition-colors">
                  FAQ
                </Link>
              </li>
              <li>
                <Link to="/partners" className="text-slate-400 hover:text-teal-400 transition-colors">
                  Partners
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact Info */}
          <div className="col-span-1">
            <h3 className="text-lg font-semibold mb-4 font-poppins">Connect With Us</h3>
            <div className="flex space-x-4">
              <a href="#" className="text-slate-400 hover:text-teal-400 transition-colors">
                <Facebook size={24} />
              </a>
              <a href="#" className="text-slate-400 hover:text-teal-400 transition-colors">
                <Twitter size={24} />
              </a>
              <a href="#" className="text-slate-400 hover:text-teal-400 transition-colors">
                <Instagram size={24} />
              </a>
              <a href="#" className="text-slate-400 hover:text-teal-400 transition-colors">
                <Linkedin size={24} />
              </a>
            </div>
            <div className="mt-4">
              <p className="text-slate-400">Email: contact@ecoficient.com</p>
              <p className="text-slate-400">Phone: +1 (555) 123-4567</p>
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-slate-800 mt-12 pt-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-slate-400 text-sm">
              &copy; {new Date().getFullYear()} Ecoficient. All rights reserved.
            </p>
            <div className="flex space-x-6 mt-4 md:mt-0">
              <Link to="/privacy" className="text-slate-400 hover:text-teal-400 transition-colors text-sm">
                Privacy Policy
              </Link>
              <Link to="/terms" className="text-slate-400 hover:text-teal-400 transition-colors text-sm">
                Terms of Service
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};