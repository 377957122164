import { useState } from 'react';
import { motion } from 'framer-motion';
import { 
  LineChart, Line, AreaChart, Area, BarChart, Bar,
  XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
  PieChart, Pie, Cell
} from 'recharts';
import { 
  BarChart3, Leaf, Droplets,
  Recycle, Zap
} from 'lucide-react';

// Sample data - In a real app, this would come from your backend
const monthlyData = [
  { month: 'Jan', emissions: 120, energy: 150, water: 200, waste: 80 },
  { month: 'Feb', emissions: 110, energy: 140, water: 180, waste: 75 },
  { month: 'Mar', emissions: 130, energy: 160, water: 190, waste: 85 },
  { month: 'Apr', emissions: 100, energy: 130, water: 170, waste: 70 },
  { month: 'May', emissions: 90, energy: 120, water: 160, waste: 65 },
  { month: 'Jun', emissions: 85, energy: 110, water: 150, waste: 60 }
];

const energyMix = [
  { name: 'Solar', value: 35, color: '#F59E0B' },
  { name: 'Wind', value: 25, color: '#3B82F6' },
  { name: 'Hydro', value: 20, color: '#10B981' },
  { name: 'Biomass', value: 15, color: '#8B5CF6' },
  { name: 'Natural Gas', value: 5, color: '#6B7280' }
];

const metrics = [
  {
    icon: Leaf,
    title: 'Carbon Reduction',
    value: '25%',
    trend: '+5%',
    positive: true
  },
  {
    icon: Zap,
    title: 'Energy Efficiency',
    value: '82%',
    trend: '+3%',
    positive: true
  },
  {
    icon: Droplets,
    title: 'Water Usage',
    value: '-15%',
    trend: '-2%',
    positive: true
  },
  {
    icon: Recycle,
    title: 'Waste Recycled',
    value: '78%',
    trend: '+4%',
    positive: true
  }
];

const timeRanges = ['Last 6 Months', 'Last Year', 'All Time'];

export const MetricsDashboard = () => {
  const [selectedRange, setSelectedRange] = useState('Last 6 Months');

  return (
    <div className="flex-1 w-full">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 pt-36">
        {/* Header */}
        <div className="mb-8">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="flex items-center gap-3 mb-4"
          >
            <BarChart3 className="w-8 h-8 text-teal-400" />
            <h1 className="text-3xl font-bold text-white">Sustainability Metrics Dashboard</h1>
          </motion.div>
          <p className="text-gray-400">
            Track and analyze your organization's environmental impact metrics in real-time.
          </p>
        </div>

        {/* Time Range Selector */}
        <div className="flex gap-4 mb-8">
          {timeRanges.map((range) => (
            <button
              key={range}
              onClick={() => setSelectedRange(range)}
              className={`px-4 py-2 rounded-lg text-sm font-medium transition-all ${
                selectedRange === range
                  ? 'bg-teal-500 text-white'
                  : 'bg-gray-800 text-gray-400 hover:bg-gray-700'
              }`}
            >
              {range}
            </button>
          ))}
        </div>

        {/* Key Metrics Grid */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8"
        >
          {metrics.map((metric, index) => (
            <div
              key={index}
              className="bg-gray-900/50 backdrop-blur-xl rounded-xl p-6 border border-white/10 hover:border-teal-500/50 transition-all duration-300 glow-effect-subtle"
            >
              <div className="flex items-center gap-4 mb-4">
                <div className="w-12 h-12 bg-teal-500/10 rounded-lg flex items-center justify-center">
                  <metric.icon className="w-6 h-6 text-teal-400" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-white">{metric.title}</h3>
                  <div className="flex items-center gap-2">
                    <span className="text-2xl font-bold text-white">{metric.value}</span>
                    <span className={`text-sm ${metric.positive ? 'text-green-400' : 'text-red-400'}`}>
                      {metric.trend}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </motion.div>

        {/* Charts Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Emissions Trend */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.6 }}
            className="bg-gray-900/50 backdrop-blur-xl rounded-xl p-6 border border-white/10 glow-effect"
          >
            <h3 className="text-xl font-semibold text-white mb-6">Carbon Emissions Trend</h3>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={monthlyData}>
                  <defs>
                    <linearGradient id="emissionsGradient" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#14B8A6" stopOpacity={0.3} />
                      <stop offset="95%" stopColor="#14B8A6" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                  <XAxis dataKey="month" stroke="#9CA3AF" />
                  <YAxis stroke="#9CA3AF" />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: '#1F2937',
                      border: '1px solid rgba(255, 255, 255, 0.1)',
                      borderRadius: '0.5rem'
                    }}
                  />
                  <Area
                    type="monotone"
                    dataKey="emissions"
                    stroke="#14B8A6"
                    fill="url(#emissionsGradient)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </motion.div>

          {/* Energy Mix */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.6 }}
            className="bg-gray-900/50 backdrop-blur-xl rounded-xl p-6 border border-white/10 glow-effect"
          >
            <h3 className="text-xl font-semibold text-white mb-6">Energy Mix</h3>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={energyMix}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={100}
                    paddingAngle={5}
                    dataKey="value"
                  >
                    {energyMix.map((entry, index) => (
                      <Cell key={index} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip
                    contentStyle={{
                      backgroundColor: '#1F2937',
                      border: '1px solid rgba(255, 255, 255, 0.1)',
                      borderRadius: '0.5rem'
                    }}
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 mt-4">
              {energyMix.map((source, index) => (
                <div key={index} className="flex items-center gap-2">
                  <div
                    className="w-3 h-3 rounded-full"
                    style={{ backgroundColor: source.color }}
                  />
                  <span className="text-sm text-gray-400">{source.name}</span>
                </div>
              ))}
            </div>
          </motion.div>

          {/* Resource Usage */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4, duration: 0.6 }}
            className="bg-gray-900/50 backdrop-blur-xl rounded-xl p-6 border border-white/10 glow-effect"
          >
            <h3 className="text-xl font-semibold text-white mb-6">Resource Usage</h3>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={monthlyData}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                  <XAxis dataKey="month" stroke="#9CA3AF" />
                  <YAxis stroke="#9CA3AF" />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: '#1F2937',
                      border: '1px solid rgba(255, 255, 255, 0.1)',
                      borderRadius: '0.5rem'
                    }}
                  />
                  <Bar dataKey="water" fill="#3B82F6" />
                  <Bar dataKey="waste" fill="#8B5CF6" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </motion.div>

          {/* Energy Efficiency */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5, duration: 0.6 }}
            className="bg-gray-900/50 backdrop-blur-xl rounded-xl p-6 border border-white/10 glow-effect"
          >
            <h3 className="text-xl font-semibold text-white mb-6">Energy Efficiency</h3>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={monthlyData}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                  <XAxis dataKey="month" stroke="#9CA3AF" />
                  <YAxis stroke="#9CA3AF" />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: '#1F2937',
                      border: '1px solid rgba(255, 255, 255, 0.1)',
                      borderRadius: '0.5rem'
                    }}
                  />
                  <Line
                    type="monotone"
                    dataKey="energy"
                    stroke="#10B981"
                    strokeWidth={2}
                    dot={{ fill: '#10B981' }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};
