import { motion } from 'framer-motion';
import { BarChart, FileText, Download, ArrowRight } from 'lucide-react';

export const ESG = () => {
  const resources = [
    {
      title: 'ESG Reporting Framework Guide',
      category: 'Framework',
      description: 'Comprehensive guide to ESG reporting frameworks and standards.',
      downloadable: true,
      link: '#'
    },
    {
      title: 'ESG Metrics Calculator',
      category: 'Tool',
      description: 'Interactive tool for calculating and tracking ESG metrics.',
      downloadable: false,
      link: '#'
    },
    {
      title: 'ESG Implementation Roadmap',
      category: 'Strategy',
      description: 'Step-by-step guide for implementing ESG practices in your organization.',
      downloadable: true,
      link: '#'
    }
  ];

  return (
    <div className="min-h-screen pt-32">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-6 font-poppins">
          ESG Resources
        </h1>
        <p className="text-xl text-slate-400 max-w-3xl mb-12">
          Access comprehensive ESG resources, tools, and frameworks.
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {resources.map((resource, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-gray-900/50 backdrop-blur-lg rounded-xl p-6 border border-gray-800 hover:border-teal-500 transition-all"
            >
              <div className="w-12 h-12 bg-teal-500/10 rounded-lg flex items-center justify-center mb-4">
                {resource.downloadable ? (
                  <FileText className="w-6 h-6 text-teal-400" />
                ) : (
                  <BarChart className="w-6 h-6 text-teal-400" />
                )}
              </div>
              <div className="text-sm text-teal-400 mb-2">{resource.category}</div>
              <h3 className="text-xl font-semibold text-white mb-2">{resource.title}</h3>
              <p className="text-slate-400 mb-4">{resource.description}</p>
              <a
                href={resource.link}
                className="inline-flex items-center text-teal-400 hover:text-teal-300 transition-colors"
              >
                {resource.downloadable ? (
                  <>
                    <Download className="w-4 h-4 mr-2" />
                    Download
                  </>
                ) : (
                  <>
                    Access Tool
                    <ArrowRight className="w-4 h-4 ml-2" />
                  </>
                )}
              </a>
            </motion.div>
          ))}
        </div>

        {/* ESG Score Calculator Preview */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="mt-16 bg-gray-900/50 backdrop-blur-lg rounded-xl p-8 border border-gray-800"
        >
          <h2 className="text-2xl font-bold text-white mb-4">ESG Score Calculator</h2>
          <p className="text-slate-400 mb-6">
            Use our interactive calculator to assess your organization's ESG performance.
          </p>
          <button className="bg-teal-500 hover:bg-teal-400 text-white px-6 py-2 rounded-lg transition-colors">
            Launch Calculator
          </button>
        </motion.div>
      </motion.div>
    </div>
  );
};
